
import React from 'react'
import { RiMessage2Fill } from "react-icons/ri";
import { FileUploader } from "react-drag-drop-files";
import { BiMailSend } from "react-icons/bi";
import contact_bg from '../assets/img/contact-bg.webp';
import contact_bg_png from '../assets/img/contact-bg.png';
import { FaWhatsapp } from "react-icons/fa6";
import { MdOutlinePermPhoneMsg } from "react-icons/md";
import { PiPhoneCall } from "react-icons/pi";
import Contact_form from './Contact_form';
import {
  Link
} from "react-router-dom";
const Contact = () => {
 
 
  
  return (
    <>
    <section className='c-contact-pw'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-6 col-md-12'>
                    <div className='cp-contact-left'>
                        <div className='cp-contact-hw'>
                            <h5>
                              <span>
                              <RiMessage2Fill />
                            </span>
                                Contact us
                            </h5>
                            <h1 className='mb-3'>Reach Out Us</h1>
                            <p>Have a project in mind? Let's talk.</p>
                        </div>
                       <Contact_form />
                    </div>
                </div>
                <div className='col-lg-6 col-md-12'>
                    <div className='cp-contact-info'>
                        <div className='text-center'>
                            <picture>
                              <source srcset={contact_bg} type="image/webp" alt="visuals clipping " />
                              <source srcset={contact_bg_png} type="image/png" alt="visuals clipping "/>
                              <img src={contact_bg_png} alt="yvisuals clipping"  />
                            </picture>  
                        </div>
                        <h2>Contact Our Team</h2>
                        <p>Questions, comments, or suggestions?<br></br> Simply fill in the form and we’ll be in touch shortly</p>
                        <a href="mailto:info@visualsclipping.com">
                            <div class="d-flex align-items-center mb-4">
                                <div class="info-icon">
                                  <BiMailSend />
                                </div>
                                <div class="info-item">
                                <p>Email</p>
                                <p>info@visualsclipping.com</p>
                                </div>
                            </div>
                            
                        </a>
                        <a href="tel:+91 9654548666">
                            <div class="d-flex align-items-center mb-4">
                                <div class="info-icon">
                                <PiPhoneCall />
                                </div>
                                <div class="info-item">
                                <p>Phone
                                </p>
                                <p>+91 9654548666</p>
                                </div>
                            </div>
                            
                        </a>
                        <a href="tel:+ 1(888)535-7543">
                            <div class="d-flex align-items-center mb-4">
                                <div class="info-icon">
                                <MdOutlinePermPhoneMsg />
                                </div>
                                <div class="info-item">
                                <p>USA/Canada Toll Free</p>
                                <p> + 1(888)535-7543</p>
                                </div>
                            </div>
                            
                        </a>
                        <a href="https://api.whatsapp.com/send?phone=9654548666">
                            <div class="d-flex align-items-center mb-4">
                                <div class="info-icon">
                                    <FaWhatsapp />
                                </div>
                                <div class="info-item">
                                <p>Whatsapp</p>
                                <p> +91 9654548666</p>
                                </div>
                            </div>
                            
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>



    </>
  )
}

export default Contact


import React from 'react';
import {
    Link
  } from "react-router-dom";
import Slider from "react-slick";
import { PiSealCheckDuotone } from "react-icons/pi";
import { MdOutlineDesignServices } from "react-icons/md";
import { LuTimerReset } from "react-icons/lu";
import { RiScissorsCutLine } from "react-icons/ri";
import { IoPricetagsOutline } from "react-icons/io5";
import { MdOutlineWorkHistory } from "react-icons/md";

import { BeforeAfter } from 'react-simple-before-after'

import After_Slider_1 from '../assets/img/after-before/online-shop/after-1.webp';
import After_Slider_2 from '../assets/img/after-before/online-shop/after-2.webp';
import After_Slider_3 from '../assets/img/after-before/online-shop/after-3.webp';
import After_Slider_4 from '../assets/img/after-before/online-shop/after-4.webp';
import After_Slider_5 from '../assets/img/after-before/online-shop/after-5.webp';

import After_Slider_6 from '../assets/img/after-before/online-shop/after-6.webp';
import After_Slider_7 from '../assets/img/after-before/online-shop/after-7.webp';
import After_Slider_8 from '../assets/img/after-before/online-shop/after-8.webp';
import After_Slider_9 from '../assets/img/after-before/online-shop/after-9.webp';
import After_Slider_10 from '../assets/img/after-before/online-shop/after-10.webp';

import After_Slider_11 from '../assets/img/after-before/online-shop/after-11.webp';
import After_Slider_12 from '../assets/img/after-before/online-shop/after-12.webp';
import After_Slider_13 from '../assets/img/after-before/online-shop/after-13.webp';
import After_Slider_14 from '../assets/img/after-before/online-shop/after-14.webp';




import Before_Slider_1 from '../assets/img/after-before/online-shop/before-1.webp';
import Before_Slider_2 from '../assets/img/after-before/online-shop/before-2.webp';
import Before_Slider_3 from '../assets/img/after-before/online-shop/before-3.webp';
import Before_Slider_4 from '../assets/img/after-before/online-shop/before-4.webp';
import Before_Slider_5 from '../assets/img/after-before/online-shop/before-5.webp';

import Before_Slider_6 from '../assets/img/after-before/online-shop/before-6.webp';
import Before_Slider_7 from '../assets/img/after-before/online-shop/before-7.webp';
import Before_Slider_8 from '../assets/img/after-before/online-shop/before-8.webp';
import Before_Slider_9 from '../assets/img/after-before/online-shop/before-9.webp';
import Before_Slider_10 from '../assets/img/after-before/online-shop/before-10.webp';

import Before_Slider_11 from '../assets/img/after-before/online-shop/before-11.webp';
import Before_Slider_12 from '../assets/img/after-before/online-shop/before-12.webp';
import Before_Slider_13 from '../assets/img/after-before/online-shop/before-13.webp';
import Before_Slider_14 from '../assets/img/after-before/online-shop/before-14.webp';

import Service_accodian_png from '../assets/img/all-the-retouching-services-you-need.png';
import Service_accodian from '../assets/img/all-the-retouching-services-you-need.webp';


import Logo_png from '../assets/img/logo.png' 
import Logo_webp from '../assets/img/logo.webp' 
import { lazy,Suspense  } from 'react';
import Img_Icon from '../assets/img/img-icon.webp';
import Img_Icon_1 from '../assets/img/banner-icon-1.webp';
import Img_Icon_2 from '../assets/img/banner-icon-2.webp';
import Img_Icon_3 from '../assets/img/banner-icon-3.webp';
import Img_Icon_4 from '../assets/img/banner-icon-4.webp';

import Img_Banner_1 from '../assets/img/ecommerce-photo-editing-banner.webp';
import Img_Banner_1_png from '../assets/img/ecommerce-photo-editing-banner.png';

import Chat_img from '../assets/img/chat.webp';
import Chat_img_png from '../assets/img/chat.png';
import Testi_1 from '../assets/img/testi-icon-1.webp';
import Feature_New_1 from '../assets/img/feature-new-3.webp';
import Feature_New_2 from '../assets/img/feature-new-2.webp';
import Feature_New_3 from '../assets/img/feature-new-1.webp';

import Satisfied from '../assets/img/100-satisfied.webp';
import Marquee from "react-fast-marquee";

import Banner_full from '../assets/img/ecommerce-color-matching.webp'

import Company_1 from '../assets/img/px-conversions/company-1.webp';
import Company_2 from '../assets/img/px-conversions/company-2.webp';
import Company_3 from '../assets/img/px-conversions/company-3.webp';
import Company_4 from '../assets/img/px-conversions/company-4.webp';
import Company_5 from '../assets/img/px-conversions/company-5.webp';
import Company_6 from '../assets/img/px-conversions/company-6.webp';
import Company_7 from '../assets/img/px-conversions/company-7.webp';
import { BsShieldFillCheck } from "react-icons/bs";

import Gallery_img_1 from '../assets/img/protfolio/ecommerce/gallery-1.webp';
import Gallery_img_png_1 from '../assets/img/protfolio/ecommerce/gallery-1.png';

import Gallery_img_2 from '../assets/img/protfolio/ecommerce/gallery-2.webp';
import Gallery_img_png_2 from '../assets/img/protfolio/ecommerce/gallery-2.png';


import Gallery_img_3 from '../assets/img/protfolio/ecommerce/gallery-3.webp';
import Gallery_img_png_3 from '../assets/img/protfolio/ecommerce/gallery-3.png';


import Gallery_img_4 from '../assets/img/protfolio/ecommerce/gallery-4.webp';
import Gallery_img_png_4 from '../assets/img/protfolio/ecommerce/gallery-4.png';


import Gallery_img_5 from '../assets/img/protfolio/ecommerce/gallery-5.webp';
import Gallery_img_png_5 from '../assets/img/protfolio/ecommerce/gallery-5.png';

const About_setion2 = lazy(() => import('./home/About_setion.js'));

const Ecommerce_Photo_Editing  = () => {
  var settings_1 = {
    dots: true,
    arrows:false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
<section className="c-banner-w">
	<div className="container">
		<div className="row align-items-center">
			<div className="col-lg-6 col-md-12">
				<div className="c-banner-con">
					<h5>
                    <img src={Img_Icon} alt="visuals clipping " />

                            Ecommerce Photo Editing 
					</h5>
					<h1>Ecommerce     <span >Photo Editing  </span> Services!</h1>
                    <ul>	
						<li>
							<div className="c-banner-feature">
								<span>	
                                <img src={Img_Icon_1} alt="visuals clipping " />	
								</span>
								100% Satisfaction
							</div>
						</li>	
						<li>
							<div className="c-banner-feature">
								<span>	
                                    <img src={Img_Icon_2} alt="visuals clipping " />
								</span>
								No Advanced Payment
							</div>
						</li>
						<li>
							<div className="c-banner-feature">
								<span>	
                                     <img src={Img_Icon_3} alt="visuals clipping " />
								</span>
								
								Unlimited Revision
							</div>
						</li>
						<li>
							<div className="c-banner-feature">
								<span>	
                                    <img src={Img_Icon_4} alt="visuals clipping " />	
								</span>
								
								10 Image Free Trail
							</div>
						</li>
					</ul>
                    
					
					<div className="c-banner-btn">
						<Link  to="/contact" className="c-btn-1">
							<span>	Get Quote Now</span>
						</Link>
						<button  data-bs-toggle="modal" data-bs-target=".c-model-popup-1" className="c-btn-2">
							<span>	Get a Free Trial</span>
						</button>
					</div>
				</div>
			</div>
			<div className="col-lg-6 col-md-12">
				<div className="c-banner-slider">
					<div className="c-banner-slider-list-w">
                    <Slider {...settings_1}>
                           <div className="c-banner-slider-list">
                           <picture>
                                <source srcset={Img_Banner_1} type="image/webp" alt="visuals clipping " />
                                <source srcset={Img_Banner_1_png} type="image/png" alt="visuals clipping "/>
                                <img src={Img_Banner_1_png} alt="yvisuals clipping"  />
                            </picture>
                               
                                <Link href="/">
                                <picture>
                                <source srcset={Chat_img} type="image/webp" alt="visuals clipping " />
                                <source srcset={Chat_img_png} type="image/png" alt="visuals clipping "/>
                                <img src={Chat_img_png} alt="yvisuals clipping"  />
                                    </picture>
                                </Link>
                            </div>
                            <div className="c-banner-slider-list">
                            <picture>
                                <source srcset={Img_Banner_1} type="image/webp" alt="visuals clipping " />
                                <source srcset={Img_Banner_1_png} type="image/png" alt="visuals clipping "/>
                                <img src={Img_Banner_1_png} alt="yvisuals clipping"  />
                            </picture> 
                            <Link href="/">
                                <picture>
                                <source srcset={Chat_img} type="image/webp" alt="visuals clipping " />
                                <source srcset={Chat_img_png} type="image/png" alt="visuals clipping "/>
                                <img src={Chat_img_png} alt="yvisuals clipping"  />
                                    </picture>
                                </Link>
                            </div>
                            
                        </Slider>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section className='c-about-w c-featur-pw'>
    <div className='container'>
        <div className='c-about-hw'>
             <h2>Benefits to outsourc Ecommerce Photo Editing  <br></br> Services to  <span>Visuals Clipping?</span> </h2>
             <p>Advantages of Partnering with Visuals Clipping.</p>
           
        </div>
        <div className='cp-fea-list'>

<div className='cp-fea-list-box'>
    <div className='cp-fea-list-box-in'>
        <span>
            <LuTimerReset />
        </span>
        Time Saving
    </div>
</div>
<div className='cp-fea-list-box'>
    <div className='cp-fea-list-box-in'>
        <span>
            <RiScissorsCutLine />
        </span>
        Accuracy
    </div>
    <div className='cp-fea-list-box-in'>
        <span>
            <MdOutlineDesignServices />
        </span>
        100% <br></br>Customized <br></br> Service
    </div>
</div>

<div className='cp-fea-list-box c-fea-midel'>
    <div className='cp-fea-list-box-in'>
        <picture>
            <source srcset={Logo_webp} type="image/webp" alt="visuals clipping logo" />
            <source srcset={Logo_png} type="image/jpeg" alt="visuals clipping logo"/>
            <img src={Logo_png} alt="yazzoo homepage banner"  />
        </picture>
    </div>
</div>
<div className='cp-fea-list-box c-fea-right'>
    <div className='cp-fea-list-box-in'>
        <span>
        <MdOutlineWorkHistory />
        </span>
        Professional <br></br> Work
    </div>
    <div className='cp-fea-list-box-in'>
        <span>
            <IoPricetagsOutline />
        </span>
        Cost Effective
    </div>
</div>
<div className='cp-fea-list-box c-fea-right'>
    <div className='cp-fea-list-box-in'>
        <span>
            <BsShieldFillCheck />
        </span>
        Highest <br></br>standard
    </div>
</div>

</div>
    </div>
</section>  
<section className='c-service-accodian'>
    <div className='container'>
        <div className='row align-items-center'>
            <div className='col-md-6'>
                <div className='c-service-img'>
                    <picture>
                        <source srcset={Service_accodian} type="image/webp" alt="visuals clipping " />
                        <source srcset={Service_accodian_png} type="image/png" alt="visuals clipping "/>
                        <img src={Service_accodian_png} alt="yvisuals clipping"  />
                    </picture>     
                </div>
            </div>
            <div className='col-md-6'>
                    <div className='c-about-hw c-faq-group-in c-service-faq'>
                    <h2>Most Affordable Ecommerce Photo Editing with   <span>24 Hours Delivery.</span>   </h2>
                    <p>The success of your eCommerce business largely depends on the visual appeal of your products. Attracting customers with stunning image retouching is the key to ever-increasing sales. That’s why we offer professional eCommerce photo editing services to help make your products more & more saleable.We understand that the eCommerce companies require bulk photo retouching services without compromising the quality. Yet, without fail, we meet deadlines while still producing high-quality work</p>
                    <div className='row justify-content-center' >
                        <div className='col-md-12'>
                        <div class="accordion accordion-flush" id="accordionFlushExample-2">
                            <div class="accordion-item">
                                <h2 class="accordion-header" >
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse-3" aria-expanded="false" >
                                Comprehensive Ecommerce Photo Editing Services
                                </button>
                                </h2>
                                <div id="flush-collapse-3" class="accordion-collapse collapse show "  data-bs-parent="#accordionFlushExample-2">
                                <div class="accordion-body">
                                    
                                <p>If you are looking for an eCommerce product photo editing solution, We offer a wide range of services, from advanced retouching to complete product photography editing. Our team of highly skilled editors can transform your images into stunning results that will make your products stand out from the competition. In addition, we offer a quick turnaround time and a 100% satisfaction guarantee, so you can be sure you will be happy with the final edited photos.</p>

                                <p>Our eCommerce photo editing service is ideal for apparel, shoes, sunglasses, furniture, fashion accessories, and other eCommerce businesses because we offer a wide range of editing options to suit your needs. We are giving our services globally, and we have clients in the USA, UK, France, Switzerland, Canada, Denmark, Finland, Belgium, Norway, Italy, Germany, South Africa, and many other countries. If you are Looking for professional eCommerce photo retouching, you can rely on us because we have more than 10 years of experience.</p>
                                    
                                </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" >
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse-4" aria-expanded="false" >
                                E-Commerce Image Editing with Proven Expertise
                                </button>
                                </h2>
                                <div id="flush-collapse-4" class="accordion-collapse collapse"  data-bs-parent="#accordionFlushExample-2">
                                <div class="accordion-body">
                                    <p><a href="https://www.visualsclipping.com/">Visuals Clipping is an image editing service</a> provider with vast experience in this field. We offer impeccable image editing services to e-commerce businesses that require quality images for their products. We have a team of highly skilled Photoshop editors who can handle any image editing request.</p>
                                    <p>Some of the services we offer include:</p>
                                    <ul>
                                        <li>Image Cropping &amp; Resizing.</li>
                                        <li>Add/ remove border, watermark, and shadows.</li>
                                        <li>100% customized retouching services.</li>
                                        <li>Get retouched images for multi-platform</li>
                                        <li>All types of product or model photo retouching.</li>
                                        <li>Multiple background options.</li>
                                    </ul>
                                    <p>We are committed to providing our clients with the best possible service and always go the extra mile to exceed their expectations. If you require e-commerce image editing services, then Visuals Clipping is the perfect choice for you.</p>
                                  </div>
                                </div>
                            </div>

                            <div class="accordion-item">
                                <h2 class="accordion-header" >
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse-5" aria-expanded="false" >
                                Get ghost mannequin retouching or model superimpose model?
                                </button>
                                </h2>
                                <div id="flush-collapse-5" class="accordion-collapse collapse"  data-bs-parent="#accordionFlushExample-2">
                                <div class="accordion-body">
                                    <p><a href="https://visualsstock.co.in/invisible-ghost-mannequin-photo-editing">Ghost mannequins</a> are often used in apparel product photography, as they allow the product to be the focus of the image. Removing the mannequin's body gives the product more attention and makes it appear floating in mid-air. In addition, ghost mannequin retouching can make the product appear more desirable and make the photo more eye-catching. We offer <a href="https://visualsstock.co.in/invisible-ghost-mannequin-photo-editing">high-quality ghost mannequin retouching</a> to make your image stand out. This is a great way to notice your product and look more professional. We also provide to superimpose the model to replace the mannequin. We replace the mannequin with the model to attract more users. By using these services, you can get a better conversion rate on your website.</p>
                                    <ul>
                                        <li>Wrinkles, folds, or creases Removal Service.</li>
                                        <li>Get Neck, bottom, and hand Joint Service.</li>
                                        <li>Dust &amp; Spot Removal Service.</li>
                                        <li>Get color correction or colour matching services.</li>
                                    </ul>
                                </div>
                                </div>
                            </div>

                            <div class="accordion-item">
                                <h2 class="accordion-header" >
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse-6" aria-expanded="false" >
                                360 Degree Photo Editing Service
                                </button>
                                </h2>
                                <div id="flush-collapse-6" class="accordion-collapse collapse"  data-bs-parent="#accordionFlushExample-2">
                                <div class="accordion-body">
                                    <p>At Visuals Clipping, we offer a 360-degree photo editing service that will put your business ahead. A 360-degree photo editing service is a type of photo editing service that allows you to edit photos of people or objects from all angles. This type of service is often used by businesses or individuals who need to edit photos for marketing or promotional purposes. Our 360-degree photo editing services can be used to create a variety of different types of photos, including panoramic shots, virtual tours, and more. If you're looking for a way to add a unique new perspective to your photos, a 360-degree photo editing service may be just what you need.</p>
                                    <ul>
                                        <li>Make a 360-degree product photo video</li>
                                        <li>360 Degree Image Enhancement</li>
                                    </ul>
                                </div>
                                </div>
                            </div>

                            

                          
                           
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<img src={Banner_full} className='c-100' />
<section className='c-service-tab-w c-service-quick-w'>
    <div className='container'>
          <div className='c-heading-w'>
                <h2>Quick and   <span>Effective</span> Ecommerce Photo Editing Service  </h2>
                <p>At Visuals Clipping, we understand that the background is one of the most important aspects of any image.</p>
          </div>
          <div className='c-service-tab'>
          <ul class="nav nav-pills mb-4" id="pills-tab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button class="nav-link active"  data-bs-toggle="pill" data-bs-target="#cp-tab-1" type="button" role="tab"  aria-selected="true">Apparel
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link"  data-bs-toggle="pill" data-bs-target="#cp-tab-2" type="button" role="tab"  aria-selected="false">Eyewear</button>
                  </li>

                  <li class="nav-item" role="presentation">
                    <button class="nav-link "  data-bs-toggle="pill" data-bs-target="#cp-tab-3" type="button" role="tab"  aria-selected="true">Footwear</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link"  data-bs-toggle="pill" data-bs-target="#cp-tab-4" type="button" role="tab"  aria-selected="false">Furniture
                    </button>
                  </li>

                  <li class="nav-item" role="presentation">
                    <button class="nav-link "  data-bs-toggle="pill" data-bs-target="#cp-tab-5" type="button" role="tab"  aria-selected="true"> Jewelry </button>
                  </li>

                  <li class="nav-item" role="presentation">
                    <button class="nav-link "  data-bs-toggle="pill" data-bs-target="#cp-tab-6" type="button" role="tab"  aria-selected="true"> Product </button>
                  </li>
                 
                  
                </ul>
                <div class="tab-content" id="pills-tabContent">
                  <div class="tab-pane fade show active" id="cp-tab-1" role="tabpanel"  tabindex="0">
                      <div className='row justify-content-center'>
                           <div className='col-md-4'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_1}
                                    afterImage={After_Slider_1}
                                    /* Other Props */
                                />
                            </div> 
                            <div className='col-md-4'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_9}
                                    afterImage={After_Slider_9}
                                    
                                />
                            </div> 
                            
                      </div>
                  </div>
                  <div class="tab-pane fade" id="cp-tab-2" role="tabpanel"  tabindex="0">
                      <div className='row justify-content-center'>
                           <div className='col-md-6'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_14}
                                    afterImage={After_Slider_14}
                                    /* Other Props */
                                />
                            </div> 
                            <div className='col-md-6'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_2}
                                    afterImage={After_Slider_2}
                                    
                                />
                            </div> 
                           
                      </div>
                  </div>

                  <div class="tab-pane fade " id="cp-tab-3" role="tabpanel"  tabindex="0">
                      <div className='row justify-content-center'>
                           <div className='col-md-4'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_3}
                                    afterImage={After_Slider_3}
                                    /* Other Props */
                                />
                            </div> 
                            <div className='col-md-4'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_13}
                                    afterImage={After_Slider_13}
                                    
                                />
                            </div> 
                            
                      </div>
                  </div>
                  <div class="tab-pane fade " id="cp-tab-4" role="tabpanel"  tabindex="0">
                      <div className='row justify-content-center'>
                           <div className='col-md-6'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_4}
                                    afterImage={After_Slider_4}
                                    /* Other Props */
                                />
                            </div> 
                            <div className='col-md-6'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_5}
                                    afterImage={After_Slider_5}
                                    
                                />
                            </div> 
                            
                      </div>
                  </div>
                  <div class="tab-pane fade " id="cp-tab-5" role="tabpanel"  tabindex="0">
                      <div className='row justify-content-center'>
                           <div className='col-md-4'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_6}
                                    afterImage={After_Slider_6}
                                    /* Other Props */
                                />
                            </div> 
                            <div className='col-md-4'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_7}
                                    afterImage={After_Slider_7}
                                    
                                />
                            </div> 
                      </div>
                  </div>
                  <div class="tab-pane fade " id="cp-tab-6" role="tabpanel"  tabindex="0">
                      <div className='row justify-content-center'>
                           <div className='col-md-4'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_11}
                                    afterImage={After_Slider_11}
                                    /* Other Props */
                                />
                            </div> 
                            <div className='col-md-4'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_12}
                                    afterImage={After_Slider_12}
                                    
                                />
                            </div> 
                      </div>
                  </div>
                </div>  
          </div>
    </div>
</section>


<section className='cn-feature-main cp-service-feature'>
    <div className='container'>
    <div className='c-feature-w'>
          <div className='c-heading-w'>
                <h2>What  <span>We Offer</span> </h2>
                <p>Are you in need of high-quality photo editing and retouching services?Look no further than us! Here are the top reasons why we should be your go-to choose for all your photo editing and retouching needs: </p>
          </div>
            <div className='row'>
                <div className='col-lg-4 col-md-6'>
                    <div className='c-feature-box'>
                        <span>
                            <img src={Feature_New_1} alt='Feature_1' />
                        </span>    
                           
                            <h3>Product Photo Editing!</h3>
                            <p>eCommerce is the demand of the day. Yet, selling something without giving a hands-on feel to the customer is a hard nut to crack. But, an edited image of any product can get the deal done in eCommerce’s favour. We’re one of the most trusted eCommerce product retouching companies!</p>
                       
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className='c-feature-box'>
                        <span>
                             <img src={Feature_New_1} alt='Feature_1' />
                        </span>    
                            
                            <h3>Try apparel photo editing to increase your online sale

                            </h3>
                            <p>experienced team of photoshop editors can take your photos and make them look their best, whether you're looking to advertise your clothing line or want to show off your latest fashion on your website. In addition, we offer various apparel retouching services to our clients to enhance your photo's look. We can remove apparel folds, brighten colors, and even creases to make your apparel photos professional. No matter what you're looking for, we're confident that we can help you achieve the perfect look for your photos.</p>
                       
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className='c-feature-box'>
                        <span>
                            <img src={Feature_New_1} alt='Feature_1' />
                        </span>    
                            
                            <h3>Professional Footwear Photo Editing Service

                            </h3>
                            <p>
                            Our expert photo editors can help you to take your online shoe store to the next level with top-of-the-line footwear photo retouching services. We can help you create stunning product photos to grab attention and draw in customers. <a href="https://visualsstock.co.in/">Visual Clipping</a> possesses a team of skilled editors who can enhance your footwear photos to make them look their best, so your shoes and footwear photos will stand out from the rest. We can remove blemishes, wrinkles, gums, stitching, moles, and other imperfections and add effects such as shadows or reflection. In addition, you can increase your online footwear sale by presenting them in a professional by using our retouching services.
                            </p>
                       
                    </div>
                </div>

                <div className='col-lg-4 col-md-6'>
                    <div className='c-feature-box'>
                        <span>
                            <img src={Feature_New_1} alt='Feature_1' />
                        </span>    
                           
                            <h3>Sunglass/Eyewear Photo Editing

                            </h3>
                            <p><a href="https://visualsstock.co.in/">Visuals Clipping is a top-notch photo editing company</a> that offers solutions for all your needs for Sunglass/Eyewear Photo Editing. We provide our clients with the highest quality images, using the latest technologies and software. Our team of highly skilled editors is highly skilled in all aspects of image editing, from removing backgrounds and retouching to color correction and airbrushing for sunglasses. We pride ourselves on our quick turnaround times and our ability to meet even the most challenging deadlines. We know a single solution will not make your sunglass product stand out. That is why we offer a wide range of services such as clipping, masking, retouching, and color correction.</p>
                       
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className='c-feature-box'>
                        <span>
                             <img src={Feature_New_1} alt='Feature_1' />
                        </span>    
                            
                            <h3>Furniture Photo Editing services

                            </h3>
                            <p>Furniture photography is essential for businesses that sell furniture online. It allows customers to see what the furniture looks like in real life, and it helps businesses create attractive and eye-catching listings. Here at Visuals Clipping, we offer the trending and high-quality custom furniture photo editing service that you have been looking for. With our service, you will be able to achieve the look that you want for your furniture photos, whether it be for your website, catalog, or social media. We offer a range of photo editing solutions that are not limited and can help you with everything from background removal to color correction to clipping path.</p>
                       
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className='c-feature-box'>
                        <span>
                            <img src={Feature_New_1} alt='Feature_1' />
                        </span>    
                            
                            <h3>Professional Jewelry Retouching Services

                            </h3>
                            <p><a href="https://www.visualsclipping.com/">Visuals Clipping is a leading jewelry retouching company</a> . Your jewelry images look their best using our retouching services, which are advanced and thorough. Our goal is to help you to create professional images that will grab your buyer's attention and help you sell more jewelryietms.</p>
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section className='c-testimonial-w'>
    <div className='container'>
        <div className='row align-items-center'>
            <div className='col-lg-6 col-md-12'>
              <div className='c-testi-in'>
                    <div className='c-heading-w'>
                        <h5><img src={Testi_1} alt="visuals clipping "/> Testimonial</h5>
                        <h2>What Our  <span>Client</span> Says?</h2>
                        <p>With our AI workflows, scaling to meet your photo processing needs is easy!</p>
                        <a  href="#" className="c-btn-1">
							<span>	Contact</span>
						</a>
                    </div>
              </div>  
            </div>
            <div className='col-lg-6 col-md-12'>
                <div className='c-testi-list'>
                    <div className="slider-container">
                    <Suspense fallback={ <p> Loding </p>}>
                        <About_setion2 />
                    </Suspense> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section className='c-price-table-w'>
    <div className='container'>
        <div className='c-heading-w'>
        <h2>eCommerce Retouching  <span> Price Chart </span> </h2>

        </div>
        <div class="table-responsive">
            <table class="table table-bordered">
                <tr>
                    <th>Work</th>
                    <th>Basic ($ 0.5/image)</th>
                    <th>Medium ($ 1/image)</th>
                    <th>High-end ($ 1.5 /image)</th>
                </tr>
                <tr>
                    <td> <strong>Background Removal</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Dust / Scratches removal</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Drop Shadow / Reflection</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Image Resizing</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Maximum Image Size</strong> </td> 
                    <td>1500X1500</td>
                    <td>2000X2000</td>
                    <td>No Size Limit</td>
                </tr>

                <tr>
                    <td> <strong>Image Enhancement</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Color Correction</strong> </td> 
                    <td><span></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Product Retouching</strong> </td> 
                    <td><span></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>


                <tr>
                    <td> <strong>Model Retouching</strong> </td> 
                    <td></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>


                <tr>
                    <td> <strong>Wrinkles Removel</strong> </td> 
                    <td><span></span></td>
                    <td><span></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>100% Customized Retouching</strong> </td> 
                    <td><span></span></td>
                    <td><span></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>


                <tr>
                    <td> <strong>Apparel Color Matching</strong> </td> 
                    <td><span></span></td>
                    <td><span></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Image padding</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Image Renaming</strong> </td> 
                    <td></td>
                    <td></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Multiple Format (JPG, PNG, Tiff, PSD, Other)</strong> </td> 
                    <td></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

               
            </table>
        </div>
    </div>
</section>

<section className='c-portfolio-service'>
       <div className='container'>
            <div className='c-heading-w'>
                    <h2>Our Photo Editing  <span>Portfolio </span> </h2>
                    <p>At Visuals Clipping, we understand that the background is one of the most important aspects of any image.</p>
            </div>
            <div className='c-porfolio-in'>
                <div className='row'>
                        <div className='col-lg-6 col-md-6'>
                            <div className='c-porfolio-box'> 
                                <picture>
                                    <source srcset={Gallery_img_1} type="image/webp" alt="visuals clipping " />
                                    <source srcset={Gallery_img_png_1} type="image/png" alt="visuals clipping "/>
                                    <img src={Gallery_img_png_1} alt="yvisuals clipping"  />
                                </picture>           
                            </div>         
                        </div>
                        <div className='col-lg-6 col-md-6'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6'>
                                    <div className='c-porfolio-box'> 
                                    <picture>
                                        <source srcset={Gallery_img_2} type="image/webp" alt="visuals clipping " />
                                        <source srcset={Gallery_img_png_2} type="image/png" alt="visuals clipping "/>
                                        <img src={Gallery_img_png_2} alt="yvisuals clipping"  />
                                    </picture>
                                    </div> 
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                <div className='c-porfolio-box'> 
                                    <picture>
                                        <source srcset={Gallery_img_3} type="image/webp" alt="visuals clipping " />
                                        <source srcset={Gallery_img_png_3} type="image/png" alt="visuals clipping "/>
                                        <img src={Gallery_img_png_3} alt="yvisuals clipping"  />
                                    </picture>
                                    </div> 
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                <div className='c-porfolio-box'> 
                                    <picture>
                                        <source srcset={Gallery_img_4} type="image/webp" alt="visuals clipping " />
                                        <source srcset={Gallery_img_png_4} type="image/png" alt="visuals clipping "/>
                                        <img src={Gallery_img_png_4} alt="yvisuals clipping"  />
                                    </picture>
                                    </div> 
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                <div className='c-porfolio-box'> 
                                    <picture>
                                        <source srcset={Gallery_img_5} type="image/webp" alt="visuals clipping " />
                                        <source srcset={Gallery_img_png_5} type="image/png" alt="visuals clipping "/>
                                        <img src={Gallery_img_png_5} alt="yvisuals clipping"  />
                                    </picture>
                                    </div> 
                                </div>
                            </div>
                        </div>
                </div>
                <div className='text-center'>
                <Link to="/contact" className="c-btn-1"><span>Get a Free Trial</span> </Link>
                </div>
            </div>
            <div className='c-porfolio-con'>
                <div className='row'> 
                    <div className='col-lg-6 col-md-12'>
                        <div className='c-porfolio-con'> 
                            <div className='c-heading-w text-left'> 
                                <h2>Know About Our <span>Mission</span></h2>
                                <p>We know that we are carrying a big responsibility of shouldering the weight of expectations shown by our clients when they place their orders with us. Our mission is to keep growing our solutions so that we can continue to meet the needs of our clients. We are always looking for ways to improve our image editing solutions so that we can provide the best possible experience for our clients. We value feedback and suggestions from our clients so that we can continue to grow and improve.</p>
                            </div>    
                         </div>       
                    </div>
                    <div className='col-lg-6 col-md-12'>
                        <div className='c-porfolio-con'> 
                            <div className='c-heading-w text-left'> 
                                <h2>Know About Our <span>Vision</span></h2>
                                <p>Visuals Clipping's vision is to provide value to enable our customer's long-term sustainable growth. We will achieve this by delivering high-quality photo retouching services that address the needs of our customers. We will always put our customers first and work to build lasting relationships with them. We will also continually invest in our people and our technology so that we can always be at the forefront of innovation.</p>
                            </div>    
                         </div>       
                    </div>
                </div>
            </div>
        </div> 
</section>
<section className='c-about-w c-inner-company'>
    <div className='container'>
        <div className='c-about-hw'>
            <h2>Our Trusted <span>Customers</span></h2>
            <img src={Satisfied} alt='Satisfied' />
        </div>
        <Marquee>
            <img src={Company_1} alt=''/>
            <img src={Company_2} alt=''/>
            <img src={Company_3} alt=''/>
            <img src={Company_4} alt=''/>
            <img src={Company_5} alt=''/>
            <img src={Company_6} alt=''/>
            <img src={Company_7} alt=''/>
        </Marquee>
      </div>
 </section>
</>
  )
}

export default Ecommerce_Photo_Editing









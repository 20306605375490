import React from 'react';
import {
  Link
} from "react-router-dom";
import Faq_img from '../assets/img/faq-img.webp';
import Faq_img_png from '../assets/img/faq-img.png';
const FAQ = () => {
  return (
    <>
    <section className='c-inner-banner'>
        <div className='container'>
            <div className='row align-items-center'>
                <div className='col-lg-9 col-md-8'>
                    <div className='c-inner-banner-con'>
                        <h3>Frequently Asked Questions</h3>
                        <p>Ask for what you want, and be prepared to get it.</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-4'>
                  <div className='c-inner-banner-img'>
                      <picture>
                            <source srcset={Faq_img} type="image/webp" alt="visuals clipping " />
                            <source srcset={Faq_img_png} type="image/png" alt="visuals clipping "/>
                            <img src={Faq_img_png} alt="yvisuals clipping"  />
                        </picture>
                  </div>
                </div>
            </div>
        </div>
    </section>
    <section className='c-about-w c-faq-group-main'>
     <div className='container'>
         <div className='c-about-hw c-faq-group-in'>
             
             <div className='row justify-content-center' >
                  <div className='col-md-9'>
                  <div className='c-about-hw c-faq-group-in'>
                      <div className="c-faq-list">
                        <div class="accordion" id="accordionExample">
                          <div class="accordion-item">
                            <h2 class="accordion-header" id="headingOne">
                              <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Do you have a minimum number of photos that you require for editing
                              </button>
                            </h2>
                            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                              <div class="accordion-body">
                                <p>No! We accept any number of photos, so feel free to order as many images as you need!</p>
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item">
                            <h2 class="accordion-header" id="headingTwo">
                              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Which type of customers do you work with?
                              </button>
                            </h2>
                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                              <div class="accordion-body">
                                <p>We work with a broad range of customers, including e-commercecompanies, photography studios, and marketing firms’ online jewellery sellers etc.</p>
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item">
                            <h2 class="accordion-header" id="headingThree">
                              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Can I get a free sample of your photo editing service?
                              </button>
                            </h2>
                            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                              <div class="accordion-body">
                                <p>
                                  Yes, we offer a free sample for first-time customers. <Link to="/contact">Contact</Link> us to send your free sample.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item">
                            <h2 class="accordion-header" id="headingFour">
                              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                                What are the different photo editing services offered by you?
                              </button>
                            </h2>
                            <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                              <div class="accordion-body">
                                <ul>
                                  <li> Clipping Path</li>
                                  <li>Background Removal</li>
                                  <li>eCommerce Image Editing</li>
                                  <li>High-end Jewelry Retouching</li>
                                  <li>Product Photo Editing</li>
                                  <li>Ghost Mannequin Retouching</li>
                                  <li>Color / Swatch Matching</li>
                                  <li>Automobile Retouching</li>
                                  <li>Furniture Photo Editing</li>
                                  <li>Eyewear Photo Retouching</li>
                                  <li>Footwear Photo Editing</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item">
                            <h2 class="accordion-header" id="heading5">
                              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapseThree">
                                What are the different photo editing services offered by you?
                              </button>
                            </h2>
                            <div id="collapse5" class="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordionExample">
                              <div class="accordion-body">
                                <p>You can reach us 24 hours a day, 7 days a week, and we serve customers from all parts of the world.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                </div>
                  </div>
             </div>
         </div>
         
      </div>
      {/* <div className='c-faq-white'>
          <div className='container'>
          <div className='c-about-hw c-faq-group-in'>
             <h2>Pricing   <span>FAQs</span></h2>
             <div className='row justify-content-center' >
                  <div className='col-md-9'>
                  <div class="accordion accordion-flush" id="accordionFlushExample-3">
                      <div class="accordion-item">
                        <h2 class="accordion-header" >
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse-8" aria-expanded="false" >
                              How much does it cost? - What are your pricing plans?
                          </button>
                        </h2>
                        <div id="flush-collapse-8" class="accordion-collapse collapse"  data-bs-parent="#accordionFlushExample-3">
                          <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the first item's accordion body.</div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" >
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse-9" aria-expanded="false" >
                          How do I pay?
                          </button>
                        </h2>
                        <div id="flush-collapse-9" class="accordion-collapse collapse"  data-bs-parent="#accordionFlushExample-3">
                          <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the second item's accordion body. Let's imagine this being filled with some actual content.</div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h2 class="accordion-header" >
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse-10" aria-expanded="false" >
                          Do I pay monthly and per-image?
                          </button>
                        </h2>
                        <div id="flush-collapse-10" class="accordion-collapse collapse"  data-bs-parent="#accordionFlushExample-3">
                          <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the second item's accordion body. Let's imagine this being filled with some actual content.</div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h2 class="accordion-header" >
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse-11" aria-expanded="false" >
                          What is the monthly subscription fee for?
                          </button>
                        </h2>
                        <div id="flush-collapse-11" class="accordion-collapse collapse"  data-bs-parent="#accordionFlushExample-3">
                          <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the second item's accordion body. Let's imagine this being filled with some actual content.</div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h2 class="accordion-header" >
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse-12" aria-expanded="false" >
                              Do you offer volume discounts?
                          </button>
                        </h2>
                        <div id="flush-collapse-12" class="accordion-collapse collapse"  data-bs-parent="#accordionFlushExample-3">
                          <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the second item's accordion body. Let's imagine this being filled with some actual content.</div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
             </div>
         </div>
          </div>
      </div>
      <div className='c-faq-white c-faq-blue '>
          <div className='container'>
          <div className='c-about-hw c-faq-group-in'>
             <h2>Pricing   <span>FAQs</span></h2>
             <div className='row justify-content-center' >
                  <div className='col-md-9'>
                  <div class="accordion accordion-flush" id="accordionFlushExample-4">
                      <div class="accordion-item">
                        <h2 class="accordion-header" >
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse-13" aria-expanded="false" >
                              How much does it cost? - What are your pricing plans?
                          </button>
                        </h2>
                        <div id="flush-collapse-13" class="accordion-collapse collapse"  data-bs-parent="#accordionFlushExample-4">
                          <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the first item's accordion body.</div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" >
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse-14" aria-expanded="false" >
                          How do I pay?
                          </button>
                        </h2>
                        <div id="flush-collapse-14" class="accordion-collapse collapse"  data-bs-parent="#accordionFlushExample-4">
                          <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the second item's accordion body. Let's imagine this being filled with some actual content.</div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h2 class="accordion-header" >
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse-15" aria-expanded="false" >
                          Do I pay monthly and per-image?
                          </button>
                        </h2>
                        <div id="flush-collapse-15" class="accordion-collapse collapse"  data-bs-parent="#accordionFlushExample-4">
                          <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the second item's accordion body. Let's imagine this being filled with some actual content.</div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h2 class="accordion-header" >
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse-16" aria-expanded="false" >
                          What is the monthly subscription fee for?
                          </button>
                        </h2>
                        <div id="flush-collapse-16" class="accordion-collapse collapse"  data-bs-parent="#accordionFlushExample-4">
                          <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the second item's accordion body. Let's imagine this being filled with some actual content.</div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h2 class="accordion-header" >
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse-17" aria-expanded="false" >
                              Do you offer volume discounts?
                          </button>
                        </h2>
                        <div id="flush-collapse-17" class="accordion-collapse collapse"  data-bs-parent="#accordionFlushExample-4">
                          <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the second item's accordion body. Let's imagine this being filled with some actual content.</div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
             </div>
         </div>
          </div>
      </div> */}
  </section>     
  </>
  )
}

export default FAQ

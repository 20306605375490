import "./App.css";
import "./assets/css/custom-2.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import Headers from "./components/header";
import Footer from "./components/footer";
import About from "./components/About";
import FAQ from "./components/FAQ";
import Home from "./components/home";
import Contact from "./components/Contact";
import Background_Removal from "./components/Background_Removal";
import Ghost from "./components/Ghost_Mannequin";
import Photo_Retouching from "./components/Photo_Retouching";
import Clipping_Path from "./components/Clipping_Path";
import Color_matching from "./components/Color_Matching";
import Automotive_Photo_Editing from "./components/Automotive_Photo_Editing";
import Ecommerce_photo_editing from "./components/Ecommerce_Photo_Editing";
import Jewellery_Photo_Editing from "./components/Jewellery_Photo_Editing";
import Thank_you from "./components/Thank_you.js";
import Privacy from "./components/Privacy.js";
import Terms from "./components/Terms.js";
function App() {
  return (
    <>
      <Router>
        <Headers></Headers>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/about">
            <About />
          </Route>
          <Route exact path="/faq">
            <FAQ />
          </Route>
          <Route exact path="/contact">
            <Contact />
          </Route>
          <Route exact path="/photo-background-removal-service">
            <Background_Removal />
          </Route>
          <Route exact path="/invisible-ghost-mannequin-photo-editing">
            <Ghost />
          </Route>

          <Route exact path="/photo-editing-service">
            <Photo_Retouching />
          </Route>

          <Route exact path="/image-clipping-path-service">
            <Clipping_Path />
          </Route>

          <Route exact path="/photo-color-correction-service">
            <Color_matching />
          </Route>

          <Route exact path="/automotive-photo-editing">
            <Automotive_Photo_Editing />
          </Route>

          <Route exact path="/automotive-photo-editing">
            <Automotive_Photo_Editing />
          </Route>

          <Route exact path="/ecommerce-image-editing-service">
            <Ecommerce_photo_editing />
          </Route>
          <Route exact path="/jewelry-retouching-service">
            <Jewellery_Photo_Editing />
          </Route>
          <Route exact path="/privacy-policy">
            <Privacy />
          </Route>
          <Route exact path="/terms-and-conditions">
            <Terms />
          </Route>

          
          
          <Route exact path="/thank-you">
            <Thank_you />
          </Route>
        </Switch>
        <Footer></Footer>
      </Router>
    </>
  );
}

export default App;

import React from "react";
import Logo_png from "../assets/img/logo.png";
import Logo_webp from "../assets/img/logo.webp";
import Social from "../assets/img/social.png";
import { FaFacebookF } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaPaperPlane } from "react-icons/fa6";
import Contact_form from "./Contact_form";
import { FaLinkedinIn } from "react-icons/fa";
import  Whatsapp  from "../assets/img/whats-app.png";
import { Link } from "react-router-dom";


const footer = () => {
  return (
    <>
      <footer className="c-footer-w">
        <div className="c-footer-info">
          <div className="container">
            <div className="c-footer-info-in">
              <div className="row">
                <div className="col-lg-5 col-md-12">
                  <div className="c-footer-info-left">
                    <span>
                      <img src={Social} alt="" />
                    </span>
                    <h3>USA/Canada Toll Free</h3>
                    <a href="tel:+ 1(888)535-7543">+ 1(888)535-7543</a>
                  </div>
                </div>
                <div className="col-lg-7 col-md-12">
                  <div className="c-footer-info-right">
                    <h4>Get A Free Trail Now!</h4>
                    <button data-bs-toggle="modal" data-bs-target=".c-model-popup-1" className="c-btn-2">
                      {" "}
                      <span>Contact Us</span>{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="c-footer-top">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-2 col-md-4">
                <div className="c-footer-nav">
                  <h2>Quick Links</h2>
                  <ul>
                    <li>
                      <Link to="/ecommerce-image-editing-service"> Ecommerce Photo Editing </Link>
                    </li>
                    <li>
                      <Link to="/photo-color-correction-serviceg">Color Correction Services</Link>
                    </li>
                    <li>
                      <Link to="/photo-background-removal-service">Background Removal Services</Link>
                    </li>
                    <li>
                      <Link to="/automotive-photo-editing">Automotive Photo Editing</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-4">
                <div className="c-footer-nav">
                  <h2>Quick Links</h2>
                  <ul>
                    <li>
                      <Link to="/image-clipping-path-service"> Clipping Path Services</Link>
                    </li>
                    <li>
                      <Link to="/photo-editing-service">Photo Retouching Services</Link>
                    </li>
                    <li>
                      {" "}
                      <Link to="/jewelry-retouching-service">Jewelry Image Editing</Link>
                    </li>
                    <li>
                      <Link to="/invisible-ghost-mannequin-photo-editing">Ghost mannequin</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-4">
                <div className="c-footer-nav">
                  <h2>Quick Links</h2>
                  <ul>
                    <li>
                      <Link to="/About"> About Us </Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/terms-and-conditions">Terms & Conditions</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-3 col-md-4">
                <div className="c-footer-form">
                  <h2>Contact us</h2>
                  <form>
                    <div className="form-group">
                      <input type="text" placeholder="First Name" className="form-control" />
                    </div>
                    <div className="form-group">
                      <input type="text" placeholder="Email" className="form-control" />
                    </div>
                    <div className="form-group">
                      <button type="button" className="c-btn-1">
                        <span> Send</span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-3 col-md-4">
                <div className="c-footer-other">
                  <Link to="/">
                    <picture>
                      <source srcset={Logo_webp} type="image/webp" alt="visuals clipping logo" />
                      <source srcset={Logo_png} type="image/jpeg" alt="visuals clipping logo" />
                      <img src={Logo_png} alt="yazzoo homepage banner" />
                    </picture>
                  </Link>
                  <h2>Follow us</h2>
                  <ul>
                    <li>
                      {" "}
                      <a href="https://www.facebook.com/visualsclipping">
                        <FaFacebookF />{" "}
                      </a>{" "}
                    </li>
                    <li>
                      {" "}
                      <a href="https://x.com/visualsclipping?mx=2">
                        <FaTwitter />{" "}
                      </a>{" "}
                    </li>
                    <li>
                      {" "}
                      <a href="https://www.instagram.com/visuals_clipping/">
                        <FaInstagram />{" "}
                      </a>{" "}
                    </li>
                    <li>
                      {" "}
                      <a href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQHD3u0fF1625wAAAZBdvKrg9yT0fPEAZljO0XrGdl9b2BIoIUHUyqI5trAhI3GRs3ov-YOeWJ2FxHaAwZGJNbWDrJrjTVsvnHSSWPn10McCo-ANnBkMATHlMHarJbcEXL9NRfo=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fvisuals-clipping">
                        <FaLinkedinIn />{" "}
                      </a>{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <ul className="c-other-link">
              <li>
                <Link>Privacy Policy</Link>
              </li>
              <li>
                <Link>Terms of Use</Link>
              </li>
              <li>
                <Link>Sales and Refunds</Link>
              </li>
              <li>
                <Link>Legal</Link>
              </li>
              <li>
                <Link>Site Map</Link>
              </li>
            </ul> */}
          </div>
        </div>
      </footer>

      <div className="modal fade c-model-popup-1" id="myModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                GET FREE TRIAL
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <Contact_form />
            </div>
          </div>
        </div>
      </div>
      

      <div className="quick-contact"><a href="https://api.whatsapp.com/send?phone=9654548666" target="_blank"> <img src={Whatsapp} alt="Whatsapp" loading="lazy" width="54" height="54" decoding="async" data-nimg="1" /></a></div>
    </>
  );
};

export default footer;

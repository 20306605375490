import React from 'react';
import {
    Link
  } from "react-router-dom";
import Slider from "react-slick";
import { PiSealCheckDuotone } from "react-icons/pi";
import { MdOutlineDesignServices } from "react-icons/md";
import { LuTimerReset } from "react-icons/lu";
import { RiScissorsCutLine } from "react-icons/ri";
import { IoPricetagsOutline } from "react-icons/io5";
import { MdOutlineWorkHistory } from "react-icons/md";

import { BeforeAfter } from 'react-simple-before-after'
import After_Slider_1 from '../assets/img/image-bg3.jpg';
import Before_Slider_1 from '../assets/img/image-bg4.jpg';

import Service_accodian_png from '../assets/img/model-photo-editing.png';
import Service_accodian from '../assets/img/model-photo-editing.webp';

import Banner_full from '../assets/img/photo-retouching-services.webp'

import Logo_png from '../assets/img/logo.png' 
import Logo_webp from '../assets/img/logo.webp' 
import { lazy,Suspense  } from 'react';
import Img_Icon from '../assets/img/img-icon.webp';
import Img_Icon_1 from '../assets/img/banner-icon-1.webp';
import Img_Icon_2 from '../assets/img/banner-icon-2.webp';
import Img_Icon_3 from '../assets/img/banner-icon-3.webp';
import Img_Icon_4 from '../assets/img/banner-icon-4.webp';

import Img_Banner_1 from '../assets/img/photo-retouching-banner.webp';
import Img_Banner_1_png from '../assets/img/photo-retouching-banner.png';

import Chat_img from '../assets/img/chat.webp';
import Chat_img_png from '../assets/img/chat.png';
import Testi_1 from '../assets/img/testi-icon-1.webp';
import Feature_New_1 from '../assets/img/feature-new-3.webp';
import Feature_New_2 from '../assets/img/feature-new-2.webp';
import Feature_New_3 from '../assets/img/feature-new-1.webp';



import Satisfied from '../assets/img/100-satisfied.webp';
import Marquee from "react-fast-marquee";

import Company_1 from '../assets/img/px-conversions/company-1.webp';
import Company_2 from '../assets/img/px-conversions/company-2.webp';
import Company_3 from '../assets/img/px-conversions/company-3.webp';
import Company_4 from '../assets/img/px-conversions/company-4.webp';
import Company_5 from '../assets/img/px-conversions/company-5.webp';
import Company_6 from '../assets/img/px-conversions/company-6.webp';
import Company_7 from '../assets/img/px-conversions/company-7.webp';
import { BsShieldFillCheck } from "react-icons/bs";

import Gallery_img_1 from '../assets/img/protfolio/photo-retouching/gallery-1.webp';
import Gallery_img_png_1 from '../assets/img/protfolio/photo-retouching/gallery-1.png';

import Gallery_img_2 from '../assets/img/protfolio/photo-retouching/gallery-2.webp';
import Gallery_img_png_2 from '../assets/img/protfolio/photo-retouching/gallery-2.png';


import Gallery_img_3 from '../assets/img/protfolio/photo-retouching/gallery-3.webp';
import Gallery_img_png_3 from '../assets/img/protfolio/photo-retouching/gallery-3.png';


import Gallery_img_4 from '../assets/img/protfolio/photo-retouching/gallery-4.webp';
import Gallery_img_png_4 from '../assets/img/protfolio/photo-retouching/gallery-4.png';


import Gallery_img_5 from '../assets/img/protfolio/photo-retouching/gallery-5.webp';
import Gallery_img_png_5 from '../assets/img/protfolio/photo-retouching/gallery-5.png';

const About_setion2 = lazy(() => import('./home/About_setion.js'));

const  Photo_Retouching = () => {
  var settings_1 = {
    dots: true,
    arrows:false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
<section className="c-banner-w">
	<div className="container">
		<div className="row align-items-center">
			<div className="col-lg-6 col-md-12">
				<div className="c-banner-con">
					<h5>
                    <img src={Img_Icon} alt="visuals clipping " />

                    Photo Retouching
					</h5>
					<h1>Photo    <span >Retouching  </span> Services!</h1>
					
                    <ul>	
						<li>
							<div className="c-banner-feature">
								<span>	
                                <img src={Img_Icon_1} alt="visuals clipping " />	
								</span>
								100% Satisfaction
							</div>
						</li>	
						<li>
							<div className="c-banner-feature">
								<span>	
                                    <img src={Img_Icon_2} alt="visuals clipping " />
								</span>
								No Advanced Payment
							</div>
						</li>
						<li>
							<div className="c-banner-feature">
								<span>	
                                     <img src={Img_Icon_3} alt="visuals clipping " />
								</span>
								
								Unlimited Revision
							</div>
						</li>
						<li>
							<div className="c-banner-feature">
								<span>	
                                    <img src={Img_Icon_4} alt="visuals clipping " />	
								</span>
								
								10 Image Free Trail
							</div>
						</li>
					</ul>
                    
					<div className="c-banner-btn">
						<Link  to="/contact" className="c-btn-1">
							<span>	Get Quote Now</span>
						</Link>
						<button  data-bs-toggle="modal" data-bs-target=".c-model-popup-1" className="c-btn-2">
							<span>	Get a Free Trial</span>
						</button>
					</div>
				</div>
			</div>
			<div className="col-lg-6 col-md-12">
				<div className="c-banner-slider">
					<div className="c-banner-slider-list-w">
                    <Slider {...settings_1}>
                           <div className="c-banner-slider-list">
                           <picture>
                                <source srcset={Img_Banner_1} type="image/webp" alt="visuals clipping " />
                                <source srcset={Img_Banner_1_png} type="image/png" alt="visuals clipping "/>
                                <img src={Img_Banner_1_png} alt="yvisuals clipping"  />
                            </picture>
                               
                                <Link href="/">
                                <picture>
                                <source srcset={Chat_img} type="image/webp" alt="visuals clipping " />
                                <source srcset={Chat_img_png} type="image/png" alt="visuals clipping "/>
                                <img src={Chat_img_png} alt="yvisuals clipping"  />
                                    </picture>
                                </Link>
                            </div>
                            <div className="c-banner-slider-list">
                            <picture>
                                <source srcset={Img_Banner_1} type="image/webp" alt="visuals clipping " />
                                <source srcset={Img_Banner_1_png} type="image/png" alt="visuals clipping "/>
                                <img src={Img_Banner_1_png} alt="yvisuals clipping"  />
                            </picture> 
                            <Link href="/">
                                <picture>
                                <source srcset={Chat_img} type="image/webp" alt="visuals clipping " />
                                <source srcset={Chat_img_png} type="image/png" alt="visuals clipping "/>
                                <img src={Chat_img_png} alt="yvisuals clipping"  />
                                    </picture>
                                </Link>
                            </div>
                            
                        </Slider>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section className='c-about-w c-featurw-pw'>
    <div className='container'>
        <div className='c-about-hw'>
             <h2>Benefits to outsource Photo Retouching <br></br> Services to  <span>Visuals Clipping?</span> </h2>
             <p>Why Outsource Photo Retouching to Visuals Clipping?</p>
           
        </div>
        <div className='cp-fea-list'>

<div className='cp-fea-list-box'>
    <div className='cp-fea-list-box-in'>
        <span>
            <LuTimerReset />
        </span>
        Time Saving
    </div>
</div>
<div className='cp-fea-list-box'>
    <div className='cp-fea-list-box-in'>
        <span>
            <RiScissorsCutLine />
        </span>
        Accuracy
    </div>
    <div className='cp-fea-list-box-in'>
        <span>
            <MdOutlineDesignServices />
        </span>
        100% <br></br>Customized <br></br> Service
    </div>
</div>

<div className='cp-fea-list-box c-fea-midel'>
    <div className='cp-fea-list-box-in'>
        <picture>
            <source srcset={Logo_webp} type="image/webp" alt="visuals clipping logo" />
            <source srcset={Logo_png} type="image/jpeg" alt="visuals clipping logo"/>
            <img src={Logo_png} alt="yazzoo homepage banner"  />
        </picture>
    </div>
</div>
<div className='cp-fea-list-box c-fea-right'>
    <div className='cp-fea-list-box-in'>
        <span>
        <MdOutlineWorkHistory />
        </span>
        Professional <br></br> Work
    </div>
    <div className='cp-fea-list-box-in'>
        <span>
            <IoPricetagsOutline />
        </span>
        Cost Effective
    </div>
</div>
<div className='cp-fea-list-box c-fea-right'>
    <div className='cp-fea-list-box-in'>
        <span>
            <BsShieldFillCheck />
        </span>
        Highest <br></br>standard
    </div>
</div>

</div>
    </div>
</section>  
<section className='c-service-accodian'>
    <div className='container'>
        <div className='row align-items-center'>
            <div className='col-md-6'>
                <div className='c-service-img'>
                    <picture>
                        <source srcset={Service_accodian} type="image/webp" alt="visuals clipping " />
                        <source srcset={Service_accodian_png} type="image/png" alt="visuals clipping "/>
                        <img src={Service_accodian_png} alt="yvisuals clipping"  />
                    </picture>     
                </div>
            </div>
            <div className='col-md-6'>
                    <div className='c-about-hw c-faq-group-in c-service-faq'>
                    <h2>Are you looking for professional <span>photo retouching</span> services?   </h2>
                    <p>If you are looking for a professional photo retouching service, Visuals Clipping is the right choice. We offer a wide range of retouching services that can help improve the quality of your photos. We can remove cellulite in photoshop, blemishes, wrinkles, and other imperfections, and improve the overall tone and contrast of your photos. We can also add special effects and filters to your photos to give them a unique look. When you choose Visuals Clipping , you can be sure that you will be getting the highest quality retouching services available. Our team of experts are skilled in a variety of retouching techniques and can help you achieve the perfect results for your photos. We understand the importance of high-quality visuals in today's competitive market, and we are dedicated to providing our clients with the best possible service.</p>
                    <div className='row justify-content-center' >
                        <div className='col-md-12'>
                        <div class="accordion accordion-flush" id="accordionFlushExample-2">
                            <div class="accordion-item">
                                <h2 class="accordion-header" >
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse-3" aria-expanded="false" >
                                Transform Your Photos into Artistic Portraits.
                                </button>
                                </h2>
                                <div id="flush-collapse-3" class="accordion-collapse collapse show"  data-bs-parent="#accordionFlushExample-2">
                                <div class="accordion-body">
                                    <p>Whether you're looking for a new profile photo or a family portrait, we can help you get the perfect picture. We offer various portrait photo editing services to help you look your best. We can retouch your photos to remove blemishes, wrinkles, and other imperfections. Our editing process is thorough and customized for each client, ensuring that your portraits are perfect for whatever purpose you need them for. So if you're looking for portrait photo editing that goes beyond the basics, count on us to deliver the high-quality results you need.</p>
                                    <ul><li>Remove wrinkles, spots, eye bags, and braces.</li><li>Portrait photo color correction.</li><li>Remove stray hairs.</li><li>Fixing and whitening of teeth.</li></ul>

                                </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" >
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse-4" aria-expanded="false" >
                                Are you looking for high-end beauty photo editing service?
                                </button>
                                </h2>
                                <div id="flush-collapse-4" class="accordion-collapse collapse"  data-bs-parent="#accordionFlushExample-2">
                                <div class="accordion-body">Count on us for reliable, high-quality beauty retouching services for all your needs, whether you need them for print, commercial, or business purposes. Do you know why? Because we understand that everyone's standards for beauty are different. Thus, we offer a wide range of services to ensure you're happy with the final product. Whether you're looking to model photo retouching, final touches by airbrushing, a few blemishes, or want a complete makeover, we're here to help. We'll work with you to understand your vision for your photos and make it a reality.

                                <ul><li>Beauty skin smoothing service.</li><li>Facial reshaping and skin glowing.</li><li>Skin color enhancement.</li></ul>
                                  </div>
                                </div>
                            </div>

                            <div class="accordion-item">
                                <h2 class="accordion-header" >
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse-5" aria-expanded="false" >
                                  Get Impeccable Body Reshaping Editing Service
                                </button>
                                </h2>
                                <div id="flush-collapse-5" class="accordion-collapse collapse"  data-bs-parent="#accordionFlushExample-2">
                                <div class="accordion-body">
                                    <p>You can hire us if you are searching for a top-quality body reshaping photo editing company. We deliver impeccable results for businesses, print media, e-commerce, and individuals. We aim to make you look your best and guarantee you will be happy with the result. We offer a wide range of body reshaping services, such as removing wrinkles and cellulite, slimming down waistlines and thighs, and increasing breast size. We can also edit photos to make you look taller or shorter, thinner or curvier. We can make people or any individual look exactly how they want to look.</p>
                                    <ul><li>Make toned body</li><li>Remove unwanted flab</li><li>Make your legs slimmer and longer</li></ul>
                                </div>
                                </div>
                            </div>

                           
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<img src={Banner_full} className='c-100' />
<section className='c-service-tab-w'>
    <div className='container'>
          <div className='c-heading-w'>
                <h2>Quick and   <span>Effective</span> Photo Retouching Service  </h2>
                <p>At Visuals Clipping, we understand that the background is one of the most important aspects of any image.</p>
          </div>
          <div className='c-service-tab'>
          <ul class="nav nav-pills mb-4" id="pills-tab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button class="nav-link active"  data-bs-toggle="pill" data-bs-target="#cp-tab-1" type="button" role="tab"  aria-selected="true">Simple
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link"  data-bs-toggle="pill" data-bs-target="#cp-tab-2" type="button" role="tab"  aria-selected="false">Medium</button>
                  </li>

                  <li class="nav-item" role="presentation">
                    <button class="nav-link "  data-bs-toggle="pill" data-bs-target="#cp-tab-3" type="button" role="tab"  aria-selected="true">Complex</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link"  data-bs-toggle="pill" data-bs-target="#cp-tab-4" type="button" role="tab"  aria-selected="false">Intricate
                    </button>
                  </li>

                  <li class="nav-item" role="presentation">
                    <button class="nav-link "  data-bs-toggle="pill" data-bs-target="#cp-tab-5" type="button" role="tab"  aria-selected="true"> Masking </button>
                  </li>
                 
                  
                </ul>
                <div class="tab-content" id="pills-tabContent">
                  <div class="tab-pane fade show active" id="cp-tab-1" role="tabpanel"  tabindex="0">
                      <div className='row'>
                           <div className='col-md-6'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_1}
                                    afterImage={After_Slider_1}
                                    /* Other Props */
                                />
                            </div> 
                            <div className='col-md-6'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_1}
                                    afterImage={After_Slider_1}
                                    
                                />
                            </div> 
                      </div>
                  </div>
                  <div class="tab-pane fade" id="cp-tab-2" role="tabpanel"  tabindex="0">
                      <div className='row'>
                           <div className='col-md-6'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_1}
                                    afterImage={After_Slider_1}
                                    /* Other Props */
                                />
                            </div> 
                            <div className='col-md-6'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_1}
                                    afterImage={After_Slider_1}
                                    
                                />
                            </div> 
                      </div>
                  </div>

                  <div class="tab-pane fade " id="cp-tab-3" role="tabpanel"  tabindex="0">
                      <div className='row'>
                           <div className='col-md-6'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_1}
                                    afterImage={After_Slider_1}
                                    /* Other Props */
                                />
                            </div> 
                            <div className='col-md-6'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_1}
                                    afterImage={After_Slider_1}
                                    
                                />
                            </div> 
                      </div>
                  </div>
                  <div class="tab-pane fade " id="cp-tab-4" role="tabpanel"  tabindex="0">
                      <div className='row'>
                           <div className='col-md-6'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_1}
                                    afterImage={After_Slider_1}
                                    /* Other Props */
                                />
                            </div> 
                            <div className='col-md-6'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_1}
                                    afterImage={After_Slider_1}
                                    
                                />
                            </div> 
                      </div>
                  </div>
                  <div class="tab-pane fade " id="cp-tab-5" role="tabpanel"  tabindex="0">
                      <div className='row'>
                           <div className='col-md-6'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_1}
                                    afterImage={After_Slider_1}
                                    /* Other Props */
                                />
                            </div> 
                            <div className='col-md-6'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_1}
                                    afterImage={After_Slider_1}
                                    
                                />
                            </div> 
                      </div>
                  </div>
                  <div class="tab-pane fade " id="cp-tab-6" role="tabpanel"  tabindex="0">
                      <div className='row'>
                           <div className='col-md-6'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_1}
                                    afterImage={After_Slider_1}
                                    /* Other Props */
                                />
                            </div> 
                            <div className='col-md-6'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_1}
                                    afterImage={After_Slider_1}
                                    
                                />
                            </div> 
                      </div>
                  </div>
                </div>  
          </div>
    </div>
</section>


<section className='cn-feature-main cp-service-feature'>
    <div className='container'>
    <div className='c-feature-w'>
          <div className='c-heading-w'>
                <h2>What  <span>We Offer</span> </h2>
                <p>Are you in need of high-quality photo editing and retouching services?Look no further than us! Here are the top reasons why we should be your go-to choose for all your photo editing and retouching needs: </p>
          </div>
            <div className='row'>
                <div className='col-lg-4 col-md-6'>
                    <div className='c-feature-box'>
                        <span>
                            <img src={Feature_New_1} alt='Feature_1' />
                        </span>    
                           
                            <h3>Decade of experience</h3>
                            <p>Visuals Clipping is one of the leading providers of photo retouching services. We have more than 10 years of experience in the field and have worked with clients from all over the globe.</p>
                       
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className='c-feature-box'>
                        <span>
                             <img src={Feature_New_1} alt='Feature_1' />
                        </span>    
                            
                            <h3>Latest software and technologies</h3>
                            <p>At Visuals Clipping photo retouching, we use the latest software and technologies in our photo retouching services. We are constantly updating our software and technologies to make sure that we are using the best possible tools.</p>
                       
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className='c-feature-box'>
                        <span>
                            <img src={Feature_New_1} alt='Feature_1' />
                        </span>    
                            
                            <h3>Basic editing to more complex work</h3>
                            <p>At our photo retouching studio, we pride ourselves on providing high-quality retouching services to our clients. Whether you need basic editing or more complex retouching, we can help you achieve the look you desire.</p>
                       
                    </div>
                </div>

                <div className='col-lg-4 col-md-6'>
                    <div className='c-feature-box'>
                        <span>
                            <img src={Feature_New_1} alt='Feature_1' />
                        </span>    
                           
                            <h3>Competitively priced and offer discounts for bulk orders</h3>
                            <p>If you're looking for high-quality photo retouching at a competitive price, then you need to choose our service. We offer discounts for bulk orders, so you can save even more money without losing out on quality.</p>
                       
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className='c-feature-box'>
                        <span>
                             <img src={Feature_New_1} alt='Feature_1' />
                        </span>    
                            
                            <h3>Committed to meet deadlines</h3>
                            <p>At Visuals Clipping, we are committed to meeting deadlines and providing fast 24 hour delivery. We understand that when you need a retouched photo, you need it ASAP. Because of this, we provide some of the industry's fastest turnaround times.</p>
                       
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className='c-feature-box'>
                        <span>
                            <img src={Feature_New_1} alt='Feature_1' />
                        </span>    
                            
                            <h3>24 hours email and phone support</h3>
                            <p>At our company, we offer 24 hours email and phone support, so you can always reach us when you need help.</p>
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section className='c-testimonial-w'>
    <div className='container'>
        <div className='row align-items-center'>
            <div className='col-lg-6 col-md-12'>
              <div className='c-testi-in'>
                    <div className='c-heading-w'>
                        <h5><img src={Testi_1} alt="visuals clipping "/> Testimonial</h5>
                        <h2>What Our  <span>Client</span> Says?</h2>
                        <p>With our AI workflows, scaling to meet your photo processing needs is easy!</p>
                        <a  href="#" className="c-btn-1">
							<span>	Contact</span>
						</a>
                    </div>
              </div>  
            </div>
            <div className='col-lg-6 col-md-12'>
                <div className='c-testi-list'>
                    <div className="slider-container">
                    <Suspense fallback={ <p> Loding </p>}>
                        <About_setion2 />
                    </Suspense> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section className='c-price-table-w'>
    <div className='container'>
        <div className='c-heading-w'>
        <h2>Jewellery Retouching <span> Price Chart </span> </h2>

        </div>
        <div class="table-responsive">
            <table class="table table-bordered">
                <tr>
                    <th>Work</th>
                    <th>Basic ($2/image)</th>
                    <th>Medium ($3.5/image)</th>
                    <th>High- end ($5/image)</th>
                </tr>
                <tr>
                    <td> <strong>Background Removal</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Dust / Reflection removal</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Drop Shadow / Reflection</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Image Resizing</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Maximum Image Size</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Image Enhancement</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Color Correction</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Metal Smoothening</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>


                <tr>
                    <td> <strong>Metal Recolouring</strong> </td> 
                    <td></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>


                <tr>
                    <td> <strong>Diamond Replacement</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Gems /  Stone Replacement / Cleaning</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>


                <tr>
                    <td> <strong>Multiple Clipping Path</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Metal Shine Enhancement</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td></td>
                </tr>

                <tr>
                    <td> <strong>Ring Shank Redraw</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Creative Retouching</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Unwanted Elements Removal</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Multiple Format (JPG, PNG, Tiff, PSD, Other)</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Focus Stacking</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>
            </table>
        </div>
    </div>
</section>

<section className='c-portfolio-service'>
       <div className='container'>
            <div className='c-heading-w'>
                    <h2>Our Photo Editing  <span>Portfolio </span> </h2>
                    <p>At Visuals Clipping, we understand that the background is one of the most important aspects of any image.</p>
            </div>
            <div className='c-porfolio-in'>
                <div className='row'>
                        <div className='col-lg-6 col-md-6'>
                            <div className='c-porfolio-box'> 
                                <picture>
                                    <source srcset={Gallery_img_1} type="image/webp" alt="visuals clipping " />
                                    <source srcset={Gallery_img_png_1} type="image/png" alt="visuals clipping "/>
                                    <img src={Gallery_img_png_1} alt="yvisuals clipping"  />
                                </picture>           
                            </div>         
                        </div>
                        <div className='col-lg-6 col-md-6'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6'>
                                    <div className='c-porfolio-box'> 
                                    <picture>
                                        <source srcset={Gallery_img_2} type="image/webp" alt="visuals clipping " />
                                        <source srcset={Gallery_img_png_2} type="image/png" alt="visuals clipping "/>
                                        <img src={Gallery_img_png_2} alt="yvisuals clipping"  />
                                    </picture>
                                    </div> 
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                <div className='c-porfolio-box'> 
                                    <picture>
                                        <source srcset={Gallery_img_3} type="image/webp" alt="visuals clipping " />
                                        <source srcset={Gallery_img_png_3} type="image/png" alt="visuals clipping "/>
                                        <img src={Gallery_img_png_3} alt="yvisuals clipping"  />
                                    </picture>
                                    </div> 
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                <div className='c-porfolio-box'> 
                                    <picture>
                                        <source srcset={Gallery_img_4} type="image/webp" alt="visuals clipping " />
                                        <source srcset={Gallery_img_png_4} type="image/png" alt="visuals clipping "/>
                                        <img src={Gallery_img_png_4} alt="yvisuals clipping"  />
                                    </picture>
                                    </div> 
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                <div className='c-porfolio-box'> 
                                    <picture>
                                        <source srcset={Gallery_img_5} type="image/webp" alt="visuals clipping " />
                                        <source srcset={Gallery_img_png_5} type="image/png" alt="visuals clipping "/>
                                        <img src={Gallery_img_png_5} alt="yvisuals clipping"  />
                                    </picture>
                                    </div> 
                                </div>
                            </div>
                        </div>
                </div>
                <div className='text-center'>
                <Link to="/contact" className="c-btn-1"><span>Get a Free Trial</span> </Link>
                </div>
            </div>
            <div className='c-porfolio-con'>
                <div className='row'> 
                    <div className='col-lg-6 col-md-12'>
                        <div className='c-porfolio-con'> 
                            <div className='c-heading-w text-left'> 
                                <h2>Know About Our <span>Mission</span></h2>
                                <p>We know that we are carrying a big responsibility of shouldering the weight of expectations shown by our clients when they place their orders with us. Our mission is to keep growing our solutions so that we can continue to meet the needs of our clients. We are always looking for ways to improve our image editing solutions so that we can provide the best possible experience for our clients. We value feedback and suggestions from our clients so that we can continue to grow and improve.</p>
                            </div>    
                         </div>       
                    </div>
                    <div className='col-lg-6 col-md-12'>
                        <div className='c-porfolio-con'> 
                            <div className='c-heading-w text-left'> 
                                <h2>Know About Our <span>Vision</span></h2>
                                <p>Visuals Clipping's vision is to provide value to enable our customer's long-term sustainable growth. We will achieve this by delivering high-quality photo retouching services that address the needs of our customers. We will always put our customers first and work to build lasting relationships with them. We will also continually invest in our people and our technology so that we can always be at the forefront of innovation.</p>
                            </div>    
                         </div>       
                    </div>
                </div>
            </div>
        </div> 
</section>
<section className='c-about-w c-inner-company'>
    <div className='container'>
        <div className='c-about-hw'>
            <h2>Our Trusted <span>Customers</span></h2>
            <img src={Satisfied} alt='Satisfied' />
        </div>
        <Marquee>
            <img src={Company_1} alt=''/>
            <img src={Company_2} alt=''/>
            <img src={Company_3} alt=''/>
            <img src={Company_4} alt=''/>
            <img src={Company_5} alt=''/>
            <img src={Company_6} alt=''/>
            <img src={Company_7} alt=''/>
        </Marquee>
      </div>
 </section>
</>
  )
}

export default  Photo_Retouching



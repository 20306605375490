import React from 'react';
import {
    Link
  } from "react-router-dom";
import Slider from "react-slick";
import { PiSealCheckDuotone } from "react-icons/pi";
import { MdOutlineDesignServices } from "react-icons/md";
import { LuTimerReset } from "react-icons/lu";
import { RiScissorsCutLine } from "react-icons/ri";
import { IoPricetagsOutline } from "react-icons/io5";
import { MdOutlineWorkHistory } from "react-icons/md";

import { BeforeAfter } from 'react-simple-before-after'

import After_Slider_1 from '../assets/img/after-before/Clipping-Path/after-1.webp';
import After_Slider_2 from '../assets/img/after-before/Clipping-Path/after-2.webp';
import After_Slider_3 from '../assets/img/after-before/Clipping-Path/after-3.webp';
import After_Slider_4 from '../assets/img/after-before/Clipping-Path/after-4.webp';
import After_Slider_5 from '../assets/img/after-before/Clipping-Path/after-5.webp';
import After_Slider_6 from '../assets/img/after-before/Clipping-Path/after-6.webp';
import After_Slider_7 from '../assets/img/after-before/Clipping-Path/after-7.webp';
import After_Slider_8 from '../assets/img/after-before/Clipping-Path/after-8.webp';
import After_Slider_9 from '../assets/img/after-before/Clipping-Path/after-9.webp';
import After_Slider_10 from '../assets/img/after-before/Clipping-Path/after-10.webp';

import Before_Slider_1 from '../assets/img/after-before/Clipping-Path/before-1.webp';
import Before_Slider_2 from '../assets/img/after-before/Clipping-Path/before-2.webp';
import Before_Slider_3 from '../assets/img/after-before/Clipping-Path/before-3.webp';
import Before_Slider_4 from '../assets/img/after-before/Clipping-Path/before-4.webp';
import Before_Slider_5 from '../assets/img/after-before/Clipping-Path/before-5.webp';
import Before_Slider_6 from '../assets/img/after-before/Clipping-Path/before-6.webp';
import Before_Slider_7 from '../assets/img/after-before/Clipping-Path/before-7.webp';
import Before_Slider_8 from '../assets/img/after-before/Clipping-Path/before-8.webp';
import Before_Slider_9 from '../assets/img/after-before/Clipping-Path/before-9.webp';
import Before_Slider_10 from '../assets/img/after-before/Clipping-Path/before-10.webp';

import Service_accodian_png from '../assets/img/professional-clippping-path-servic.png';
import Service_accodian from '../assets/img/professional-clippping-path-service.webp';


import Logo_png from '../assets/img/logo.png' 
import Logo_webp from '../assets/img/logo.webp' 
import { lazy,Suspense  } from 'react';
import Img_Icon from '../assets/img/img-icon.webp';
import Img_Icon_1 from '../assets/img/banner-icon-1.webp';
import Img_Icon_2 from '../assets/img/banner-icon-2.webp';
import Img_Icon_3 from '../assets/img/banner-icon-3.webp';
import Img_Icon_4 from '../assets/img/banner-icon-4.webp';

import Img_Banner_1 from '../assets/img/image-clipping-banner.webp';
import Img_Banner_1_png from '../assets/img/image-clipping-banner.png';

import Chat_img from '../assets/img/chat.webp';
import Chat_img_png from '../assets/img/chat.png';
import Testi_1 from '../assets/img/testi-icon-1.webp';
import Feature_New_1 from '../assets/img/feature-new-3.webp';
import Feature_New_2 from '../assets/img/feature-new-2.webp';
import Feature_New_3 from '../assets/img/feature-new-1.webp';

import Satisfied from '../assets/img/100-satisfied.webp';
import Marquee from "react-fast-marquee";

import Company_1 from '../assets/img/px-conversions/company-1.webp';
import Company_2 from '../assets/img/px-conversions/company-2.webp';
import Company_3 from '../assets/img/px-conversions/company-3.webp';
import Company_4 from '../assets/img/px-conversions/company-4.webp';
import Company_5 from '../assets/img/px-conversions/company-5.webp';
import Company_6 from '../assets/img/px-conversions/company-6.webp';
import Company_7 from '../assets/img/px-conversions/company-7.webp';
import { BsShieldFillCheck } from "react-icons/bs";
import Banner_full from '../assets/img/background-100.jpg'

import Gallery_img_1 from '../assets/img/protfolio/clipping-path/gallery-1.webp';
import Gallery_img_png_1 from '../assets/img/protfolio/clipping-path/gallery-1.png';

import Gallery_img_2 from '../assets/img/protfolio/clipping-path/gallery-2.webp';
import Gallery_img_png_2 from '../assets/img/protfolio/clipping-path/gallery-2.png';


import Gallery_img_3 from '../assets/img/protfolio/clipping-path/gallery-3.webp';
import Gallery_img_png_3 from '../assets/img/protfolio/clipping-path/gallery-3.png';


import Gallery_img_4 from '../assets/img/protfolio/clipping-path/gallery-4.webp';
import Gallery_img_png_4 from '../assets/img/protfolio/clipping-path/gallery-4.png';


import Gallery_img_5 from '../assets/img/protfolio/clipping-path/gallery-5.webp';
import Gallery_img_png_5 from '../assets/img/protfolio/clipping-path/gallery-5.png';

const About_setion2 = lazy(() => import('./home/About_setion.js'));

const  Clipping_Path = () => {
  var settings_1 = {
    dots: true,
    arrows:false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
<section className="c-banner-w">
	<div className="container">
		<div className="row align-items-center">
			<div className="col-lg-6 col-md-12">
				<div className="c-banner-con">
					<h5>
                    <img src={Img_Icon} alt="visuals clipping " />

                    Clipping Path
					</h5>
					<h1>
             <span >Clipping Path  </span> Services!</h1>
             
					<ul>	
						<li>
							<div className="c-banner-feature">
								<span>	
                                <img src={Img_Icon_1} alt="visuals clipping " />	
								</span>
								100% Satisfaction
							</div>
						</li>	
						<li>
							<div className="c-banner-feature">
								<span>	
                                    <img src={Img_Icon_2} alt="visuals clipping " />
								</span>
								No Advanced Payment
							</div>
						</li>
						<li>
							<div className="c-banner-feature">
								<span>	
                                     <img src={Img_Icon_3} alt="visuals clipping " />
								</span>
								
								Unlimited Revision
							</div>
						</li>
						<li>
							<div className="c-banner-feature">
								<span>	
                                    <img src={Img_Icon_4} alt="visuals clipping " />	
								</span>
								
								10 Image Free Trail
							</div>
						</li>
					</ul>
                    
					<div className="c-banner-btn">
						<Link  to="/contact" className="c-btn-1">
							<span>	Get Quote Now</span>
						</Link>
						<button  data-bs-toggle="modal" data-bs-target=".c-model-popup-1" className="c-btn-2">
							<span>	Get a Free Trial</span>
						</button>
					</div>
				</div>
			</div>
			<div className="col-lg-6 col-md-12">
				<div className="c-banner-slider">
					<div className="c-banner-slider-list-w">
                    <Slider {...settings_1}>
                           <div className="c-banner-slider-list">
                           <picture>
                                <source srcset={Img_Banner_1} type="image/webp" alt="visuals clipping " />
                                <source srcset={Img_Banner_1_png} type="image/png" alt="visuals clipping "/>
                                <img src={Img_Banner_1_png} alt="yvisuals clipping"  />
                            </picture>
                               
                                <Link href="/">
                                <picture>
                                <source srcset={Chat_img} type="image/webp" alt="visuals clipping " />
                                <source srcset={Chat_img_png} type="image/png" alt="visuals clipping "/>
                                <img src={Chat_img_png} alt="yvisuals clipping"  />
                                    </picture>
                                </Link>
                            </div>
                            <div className="c-banner-slider-list">
                            <picture>
                                <source srcset={Img_Banner_1} type="image/webp" alt="visuals clipping " />
                                <source srcset={Img_Banner_1_png} type="image/png" alt="visuals clipping "/>
                                <img src={Img_Banner_1_png} alt="yvisuals clipping"  />
                            </picture> 
                            <Link href="/">
                                <picture>
                                <source srcset={Chat_img} type="image/webp" alt="visuals clipping " />
                                <source srcset={Chat_img_png} type="image/png" alt="visuals clipping "/>
                                <img src={Chat_img_png} alt="yvisuals clipping"  />
                                    </picture>
                                </Link>
                            </div>
                            
                        </Slider>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section className='c-about-w c-featurw-pw'>
    <div className='container'>
        <div className='c-about-hw'>
             <h2>Benefits to outsource Photo Retouching <br></br> Services to  <span>Clipping Path</span> </h2>
             <p>ou are not restricted to choices when you choose Visuals Clipping. Our clients receive personalized services as per their needs.</p>
           
        </div>
        <div className='cp-fea-list'>

<div className='cp-fea-list-box'>
    <div className='cp-fea-list-box-in'>
        <span>
            <LuTimerReset />
        </span>
        Time Saving
    </div>
</div>
<div className='cp-fea-list-box'>
    <div className='cp-fea-list-box-in'>
        <span>
            <RiScissorsCutLine />
        </span>
        Accuracy
    </div>
    <div className='cp-fea-list-box-in'>
        <span>
            <MdOutlineDesignServices />
        </span>
        100% <br></br>Customized <br></br> Service
    </div>
</div>

<div className='cp-fea-list-box c-fea-midel'>
    <div className='cp-fea-list-box-in'>
        <picture>
            <source srcset={Logo_webp} type="image/webp" alt="visuals clipping logo" />
            <source srcset={Logo_png} type="image/jpeg" alt="visuals clipping logo"/>
            <img src={Logo_png} alt="yazzoo homepage banner"  />
        </picture>
    </div>
</div>
<div className='cp-fea-list-box c-fea-right'>
    <div className='cp-fea-list-box-in'>
        <span>
        <MdOutlineWorkHistory />
        </span>
        Professional <br></br> Work
    </div>
    <div className='cp-fea-list-box-in'>
        <span>
            <IoPricetagsOutline />
        </span>
        Cost Effective
    </div>
</div>
<div className='cp-fea-list-box c-fea-right'>
    <div className='cp-fea-list-box-in'>
        <span>
            <BsShieldFillCheck />
        </span>
        Highest <br></br>standard
    </div>
</div>

</div>
    </div>
</section>  
<section className='c-service-accodian'>
    <div className='container'>
        <div className='row align-items-center'>
            <div className='col-md-6'>
                <div className='c-service-img'>
                    <picture>
                        <source srcset={Service_accodian} type="image/webp" alt="visuals clipping " />
                        <source srcset={Service_accodian_png} type="image/png" alt="visuals clipping "/>
                        <img src={Service_accodian_png} alt="yvisuals clipping"  />
                    </picture>     
                </div>
            </div>
            <div className='col-md-6'>
                    <div className='c-about-hw c-faq-group-in c-service-faq'>
                    <h2>100 % Accurate HandMade <span>Clipping Path</span> Services  </h2>
                    <p> Finding a reliable clipping path company is not an easy task. Especially if you want to find a provider who can offer you Best Clipping Path Service at an affordable price.Visuals Clipping is a leading clipping path company. We offer a comprehensive range of image-clipping path services to our clients.</p>
                    <p>By working with us, clients can get bulk image clipping support to finish large volumes timely. Visuals Clipping has been delivering high-quality photoshopclipping path, image masking, deep etch, channel masking, detouragephoto, and other retouching services for 10 years. In that time, we have served clients from all over the world, including the USA, the United Kingdom, Germany, France, Canada, Norway, Belgium, South Africa, Switzerland, and many other countries. We are proud to say that we have maintained a high level of customer satisfaction since we started offering photo retouching and clipping path services. We guarantee that you will be satisfied with our work and commitment that produces extraordinary results.</p>
                    <div className='row justify-content-center' >
                        <div className='col-md-12'>
                        <div class="accordion accordion-flush" id="accordionFlushExample-2">
                            <div class="accordion-item">
                                <h2 class="accordion-header" >
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse-3" aria-expanded="false" >
                                Save time and money by outsourcing to Visuals Clipping!
                                </button>
                                </h2>
                                <div id="flush-collapse-3" class="accordion-collapse collapse "  data-bs-parent="#accordionFlushExample-2">
                                <div class="accordion-body">
                                    <p>A clipping path is a graphical technique used to define an image's precise interior or exterior region to isolate or remove it from the rest of the picture. For example, we use Photoshop pen tool technique for clipping path to remove the image's unwanted background or cut out a specific object from a photo. Before deciding to move forward with any company to outsource your photo-clipping path projects, you can get a free trial to check the quality of our service. We give you a 50 images free trial facility to ensure quality, accuracy, and delivery time. We work at the most competitive rates compared to the industry.</p>
                                    <p>Are you looking for a reliable company to outsource your clipping path projects? Visuals Clipping specializes in simple or essential photo clipping path or detourage services. We take pride in our work and guarantee outstanding results. Our skilled Photoshop professionals can deliver any clipping path project quickly and efficiently. We have worked with various clients, including eCommerce companies throughout the glove, print media, online jewelry stores, online used car sellers/dealers, and other online businesses. No matter what your products are, we can help you achieve the professional look for your product which will help to increase your sales</p>
                                </div>
                                </div>
                            </div>
                            
                          

                           
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section className='c-service-tab-w'>
    <div className='container'>
          <div className='c-heading-w'>
                <h2>Quick and   <span>Effective</span> Clipping
                Path Service  </h2>
                <p>Are you in need of high-quality photo editing and retouching services?Look no further than us! Here are the top reasons why we should be your go-to choose for all your photo editing and retouching needs:</p>
          </div>
          <div className='c-service-tab'>
          <ul class="nav nav-pills mb-4" id="pills-tab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button class="nav-link active"  data-bs-toggle="pill" data-bs-target="#cp-tab-1" type="button" role="tab"  aria-selected="true">Simple
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link"  data-bs-toggle="pill" data-bs-target="#cp-tab-4" type="button" role="tab"  aria-selected="false">Medium</button>
                  </li>

                  <li class="nav-item" role="presentation">
                    <button class="nav-link"  data-bs-toggle="pill" data-bs-target="#cp-tab-2" type="button" role="tab"  aria-selected="false">Multiple</button>
                  </li>

                  <li class="nav-item" role="presentation">
                    <button class="nav-link "  data-bs-toggle="pill" data-bs-target="#cp-tab-5" type="button" role="tab"  aria-selected="true"> Masking </button>
                  </li>
                  

                  <li class="nav-item" role="presentation">
                    <button class="nav-link "  data-bs-toggle="pill" data-bs-target="#cp-tab-3" type="button" role="tab"  aria-selected="true">Complex</button>
                  </li>
                 

                  



                 
                  
                </ul>
                <div class="tab-content" id="pills-tabContent">
                  <div class="tab-pane fade show active" id="cp-tab-1" role="tabpanel"  tabindex="0">
                      <div className='row'>
                           <div className='col-md-6'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_10}
                                    afterImage={After_Slider_10}
                                    /* Other Props */
                                />
                            </div> 
                            <div className='col-md-6'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_9}
                                    afterImage={After_Slider_9}
                                    
                                />
                            </div> 
                      </div>
                  </div>
                  <div class="tab-pane fade" id="cp-tab-2" role="tabpanel"  tabindex="0">
                      <div className='row'>
                           <div className='col-md-6'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_5}
                                    afterImage={After_Slider_5}
                                    /* Other Props */
                                />
                            </div> 
                            <div className='col-md-6'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_8}
                                    afterImage={After_Slider_8}
                                    
                                />
                            </div> 
                      </div>
                  </div>

                  <div class="tab-pane fade " id="cp-tab-4" role="tabpanel"  tabindex="0">
                      <div className='row'>
                           <div className='col-md-6'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_6}
                                    afterImage={After_Slider_6}
                                    /* Other Props */
                                />
                            </div> 
                            <div className='col-md-6'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_7}
                                    afterImage={After_Slider_7}
                                    
                                />
                            </div> 
                      </div>
                  </div>
                  <div class="tab-pane fade " id="cp-tab-3" role="tabpanel"  tabindex="0">
                      <div className='row'>
                           <div className='col-md-6'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_1}
                                    afterImage={After_Slider_1}
                                    /* Other Props */
                                />
                            </div> 
                            <div className='col-md-6'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_2}
                                    afterImage={After_Slider_2}
                                    
                                />
                            </div> 
                      </div>
                  </div>
                  
                  <div class="tab-pane fade " id="cp-tab-5" role="tabpanel"  tabindex="0">
                      <div className='row'>
                           <div className='col-md-6'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_3}
                                    afterImage={After_Slider_3}
                                    /* Other Props */
                                />
                            </div> 
                            <div className='col-md-6'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_4}
                                    afterImage={After_Slider_4}
                                    
                                />
                            </div> 
                      </div>
                  </div>
                </div>  
          </div>
    </div>
</section>


<section className='cn-feature-main cp-service-feature'>
    <div className='container'>
    <div className='c-feature-w'>
          <div className='c-heading-w'>
                <h2>What  <span>We Offer</span> </h2>
                <p>Are you in need of high-quality photo editing and retouching services?Look no further than us! Here are the top reasons why we should be your go-to choose for all your photo editing and retouching needs: </p>
          </div>
            <div className='row'>
                <div className='col-lg-4 col-md-6'>
                    <div className='c-feature-box'>
                        <span>
                            <img src={Feature_New_1} alt='Feature_1' />
                        </span>    
                           
                            <h3>High-quality Hand-made clipping path
                            </h3>
                            <p>Hire Visuals Clipping to receive the best-handcrafted clipping path service. A high-quality hand-made clipping path makes your products look great, as you can remove the background of an image and use it with other backgrounds or photos. It can help create other marketing materials like product photos and infographics.</p>
                       
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className='c-feature-box'>
                        <span>
                             <img src={Feature_New_1} alt='Feature_1' />
                        </span>    
                            
                            <h3>Medium complexity Photo clipping path</h3>
                            <p>As the name suggests, a medium photo detourage or clipping path is used to make a clipping path around the subject matter of medium complexity. Our medium clipping path is processed by the photoshop pen tool giving you 100% accuracy. Our manual clipping paths services help businesses, individuals, and e-commerce companies to edit their photos professionally. Clipping Path Service allows them to achieve the perfect look for their products and websites while maintaining a high-quality image. Our services are ideal for print media houses, who can use our clipping path service to create stunning visuals for their magazines and newspapers. Our services help save time and money for our clients as we provide high-quality photos ready for publication or posting.</p>
                       
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className='c-feature-box'>
                        <span>
                            <img src={Feature_New_1} alt='Feature_1' />
                        </span>    
                            
                            <h3>Clipping Path for Complex Images?
                            </h3>
                            <p>Visuals Clipping is a pioneer company to outsource your entire clipping path or image masking work. We have been serving clipping path services globally since 2011. We always process the images in photoshop and deliver perfect clipping path images with 100% accuracy. A complex clipping path is a closed vector path or shape that cuts out an intricate image from its background. A difficult clipping path can have many different points and curves. Complex clipping path makes them ideal for cutting out images with lots of detail, like bikes, cars, hair, or other super complex products. Our services are suitable not only for simple or medium product clipping paths but also dependable for intricate product clipping paths. So, when it comes to detourage complex product photos, Image Manipulation, Image Optimization, Fashion Retouching, or even something like Jewelry Photo Retouching, where you need a complex or detailed clipping path, you can always count on us.</p>
                       
                    </div>
                </div>

                <div className='col-lg-4 col-md-6'>
                    <div className='c-feature-box'>
                        <span>
                            <img src={Feature_New_1} alt='Feature_1' />
                        </span>    
                           
                            <h3>Require multiple clipping paths?
                            </h3>
                            <p>A multiple-clipping path is a complex clipping path with additional paths carefully crafted within a single product or image. We use photoshop pen tool to produce multiple clipping path to separate the different parts of any product or photo. Multiple clipping paths can be used to isolate each part of the product or object, making it easier to manipulate the image in post-production. We provide multiple clipping path services that meet your needs and exceed your expectations. Our skilled team of <a href="https://visualsstock.co.in/photo-background-removal-service">photoshop professionals</a> will work diligently to provide you the flawless results, and our global clients can contact us to experience.</p>
                       
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className='c-feature-box'>
                        <span>
                             <img src={Feature_New_1} alt='Feature_1' />
                        </span>    
                            
                            <h3>Image masking & channel masking</h3>
                            <p>Image masking and channel masking services offered by Visuals Clipping help you make image composites, modify the background, cut out objects, and target edits so they only affect specific areas rather than the whole layer. Using it, you can hide parts of an image or layer without erasing them. Best results guaranteed!</p>
                       
                    </div>
                </div>
               
            </div>
        </div>
    </div>
</section>
<section className='c-testimonial-w'>
    <div className='container'>
        <div className='row align-items-center'>
            <div className='col-lg-6 col-md-12'>
              <div className='c-testi-in'>
                    <div className='c-heading-w'>
                        <h5><img src={Testi_1} alt="visuals clipping "/> Testimonial</h5>
                        <h2>What Our  <span>Client</span> Says?</h2>
                        <p>With our AI workflows, scaling to meet your photo processing needs is easy!</p>
                        <a  href="#" className="c-btn-1">
							<span>	Contact</span>
						</a>
                    </div>
              </div>  
            </div>
            <div className='col-lg-6 col-md-12'>
                <div className='c-testi-list'>
                    <div className="slider-container">
                    <Suspense fallback={ <p> Loding </p>}>
                        <About_setion2 />
                    </Suspense> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<img src={Banner_full} className='c-100' />
<section className='c-price-table-w'>
    <div className='container'>
        <div className='c-heading-w'>
        <h2>Clipping Path  <span> Price Chart </span> </h2>

        </div>
        <div class="table-responsive">
            <table class="table table-bordered">
                <tr>
                    <th>Work</th>
                    <th>Basic ($ 0.6/image)</th>
                    <th>Medium ($ 1/image)</th>
                    <th>Complex ($ 1.5 /image)</th>
                </tr>
                <tr>
                    <td> <strong>Product Type</strong> </td> 
                    <td>Simple</td>
                    <td>Medium</td>
                    <td>Complex</td>
                </tr>

                <tr>
                    <td> <strong>Dust / Reflection removal</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Drop Shadow / Reflection</strong> </td> 
                    <td><span></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Image Resizing</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Maximum Image Size</strong> </td> 
                    <td>1500X1500</td>
                    <td>2000X2000</td>
                    <td>No Size Limit</td>
                </tr>

                <tr>
                    <td> <strong>Image Enhancement</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Color Correction</strong> </td> 
                    <td><span></span></td>
                    <td><span></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>100% Customized Retouching</strong> </td> 
                    <td><span></span></td>
                    <td><span></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>


                <tr>
                    <td> <strong>Image padding</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>


                <tr>
                    <td> <strong>Image Renaming</strong> </td> 
                    <td></td>
                    <td></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Multiple Format (JPG, PNG, Tiff, PSD, Other)</strong> </td> 
                    <td><span></span></td>
                    <td><span></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>


               
            </table>
        </div>
    </div>
</section>

<section className='c-portfolio-service'>
       <div className='container'>
            <div className='c-heading-w'>
                    <h2>Our Photo Editing  <span>Portfolio </span> </h2>
                    <p>Transforming Ordinary Images into Extraordinary Visuals.</p>
            </div>
            <div className='c-porfolio-in'>
                <div className='row'>
                        <div className='col-lg-6 col-md-6'>
                            <div className='c-porfolio-box'> 
                                <picture>
                                    <source srcset={Gallery_img_1} type="image/webp" alt="visuals clipping " />
                                    <source srcset={Gallery_img_png_1} type="image/png" alt="visuals clipping "/>
                                    <img src={Gallery_img_png_1} alt="yvisuals clipping"  />
                                </picture>           
                            </div>         
                        </div>
                        <div className='col-lg-6 col-md-6'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6'>
                                    <div className='c-porfolio-box'> 
                                    <picture>
                                        <source srcset={Gallery_img_2} type="image/webp" alt="visuals clipping " />
                                        <source srcset={Gallery_img_png_2} type="image/png" alt="visuals clipping "/>
                                        <img src={Gallery_img_png_2} alt="yvisuals clipping"  />
                                    </picture>
                                    </div> 
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                <div className='c-porfolio-box'> 
                                    <picture>
                                        <source srcset={Gallery_img_3} type="image/webp" alt="visuals clipping " />
                                        <source srcset={Gallery_img_png_3} type="image/png" alt="visuals clipping "/>
                                        <img src={Gallery_img_png_3} alt="yvisuals clipping"  />
                                    </picture>
                                    </div> 
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                <div className='c-porfolio-box'> 
                                    <picture>
                                        <source srcset={Gallery_img_4} type="image/webp" alt="visuals clipping " />
                                        <source srcset={Gallery_img_png_4} type="image/png" alt="visuals clipping "/>
                                        <img src={Gallery_img_png_4} alt="yvisuals clipping"  />
                                    </picture>
                                    </div> 
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                <div className='c-porfolio-box'> 
                                    <picture>
                                        <source srcset={Gallery_img_5} type="image/webp" alt="visuals clipping " />
                                        <source srcset={Gallery_img_png_5} type="image/png" alt="visuals clipping "/>
                                        <img src={Gallery_img_png_5} alt="yvisuals clipping"  />
                                    </picture>
                                    </div> 
                                </div>
                            </div>
                        </div>
                </div>
                <div className='text-center'>
                <Link to="/contact" className="c-btn-1"><span>Get a Free Trial</span> </Link>
                </div>
            </div>
            <div className='c-porfolio-con'>
                <div className='row'> 
                    <div className='col-lg-6 col-md-12'>
                        <div className='c-porfolio-con'> 
                            <div className='c-heading-w text-left'> 
                                <h2>Know About Our <span>Mission</span></h2>
                                <p>We know that we are carrying a big responsibility of shouldering the weight of expectations shown by our clients when they place their orders with us. Our mission is to keep growing our solutions so that we can continue to meet the needs of our clients. We are always looking for ways to improve our image editing solutions so that we can provide the best possible experience for our clients. We value feedback and suggestions from our clients so that we can continue to grow and improve.</p>
                            </div>    
                         </div>       
                    </div>
                    <div className='col-lg-6 col-md-12'>
                        <div className='c-porfolio-con'> 
                            <div className='c-heading-w text-left'> 
                                <h2>Know About Our <span>Vision</span></h2>
                                <p>Visuals Clipping's vision is to provide value to enable our customer's long-term sustainable growth. We will achieve this by delivering high-quality photo retouching services that address the needs of our customers. We will always put our customers first and work to build lasting relationships with them. We will also continually invest in our people and our technology so that we can always be at the forefront of innovation.</p>
                            </div>    
                         </div>       
                    </div>
                </div>
            </div>
        </div> 
</section>
<section className='c-about-w c-inner-company'>
    <div className='container'>
        <div className='c-about-hw'>
            <h2>Our Trusted <span>Customers</span></h2>
            <img src={Satisfied} alt='Satisfied' />
        </div>
        <Marquee>
            <img src={Company_1} alt=''/>
            <img src={Company_2} alt=''/>
            <img src={Company_3} alt=''/>
            <img src={Company_4} alt=''/>
            <img src={Company_5} alt=''/>
            <img src={Company_6} alt=''/>
            <img src={Company_7} alt=''/>
        </Marquee>
      </div>
 </section>
</>
  )
}

export default  Clipping_Path



